import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { ProducerKitsButton } from './ProducerKitsButton'
import { useTranslation } from 'react-i18next'
import { useFunctionRequest } from 'hooks/useFunctionRequest'
import { errorLogger } from 'utils/errorLogger'
import { useSnackbar } from './useSnackbar'
import { useRouter } from 'next/router'

type CancelPlanModalProps = {
  onClose: () => void
  subscription: any
}

export const CancelPlanModal = ({
  onClose,
  subscription,
}: CancelPlanModalProps) => {
  const { t: tcommon } = useTranslation('common')
  const makeRequest = useFunctionRequest()
  const { openSnack, Snackbar } = useSnackbar()
  const { t: tsub } = useTranslation('subscription')
  const { push } = useRouter()
  const cancelSubscription = () =>
    makeRequest({
      functionName: 'cancelSubscription',
      body: { subscriptionID: subscription._id },
    })
      .then(() => {
        openSnack({
          message: tsub('cancel_subscription_cancel_success'),
          severity: 'success',
        })
        push('/account')
        onClose()
      })
      .catch(error => {
        errorLogger(error)
        openSnack({ message: error.message, severity: 'error' })
      })

  return (
    <Dialog open onClose={onClose} maxWidth='xs'>
      <Snackbar />
      <DialogTitle>Cancel Plan</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Are you sure you want to cancel your current plan?
        </Typography>
        <Typography gutterBottom>
          Your current credits can be used until the end of your subscription
          period.
        </Typography>
        <Typography gutterBottom>
          The samples you have already purchased remain available in the
          download section.
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0px 24px 24px 24px' }}>
        <ProducerKitsButton
          variant='text'
          onClick={cancelSubscription}
          size='small'
        >
          Cancel Plan
        </ProducerKitsButton>
        <ProducerKitsButton onClick={onClose} size='small'>
          {tcommon<string>('close') as string}
        </ProducerKitsButton>
      </DialogActions>
    </Dialog>
  )
}
