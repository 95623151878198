import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { TextField } from 'components/TextField'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { useAuth } from 'hooks/useAuth'
import { errorCodeCheck } from 'utils/errorCodeCheck'
import { useTranslation } from 'react-i18next'
import { firebaseObject } from 'containers/firebaseObject'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { dataLayerPush } from 'containers/GoogleTracking/utils'
import { useFunctionRequest } from 'hooks/useFunctionRequest'
import { ProducerKitsLogo } from 'components/ProducerKitsLogo'
import { SubscriptionContainer } from './SignUpForm'
import { LinkClick } from 'components/LinkClick'
import { SubscribeContentForms } from 'types/types'

type SignInFormType = {
  email: string
  password: string
}

type SignInFormProps = {
  onNavigate: (form: SubscribeContentForms) => void
}

export const SignInForm = ({ onNavigate }: SignInFormProps) => {
  const formObject = useForm<SignInFormType>()
  const { user } = useAuth()
  const { t: tcommon } = useTranslation('common')
  const makeRequest = useFunctionRequest()
  const [loadingMessage, setLoadingMessage] = useState('')
  const [error, setError] = useState('')
  const { push, replace } = useRouter()
  useEffect(() => {
    user?.uid && replace(window.location.pathname + '?subscribe=closed')
  }, [user, push, replace])

  const handleSignIn = async (data: SignInFormType) => {
    try {
      setLoadingMessage('Signing you in, please wait')
      const auth = await signInWithEmailAndPassword(
        firebaseObject.auth,
        data.email,
        data.password,
      )
      const idToken = await auth.user.getIdToken()
      dataLayerPush('sign_in_email', { email: data?.email || 'EMAIL_UNKNOWN' })
      makeRequest({
        functionName: 'activeCampaignAction',
        body: { actionName: 'moveFromInactiveAccounts' },
        idTokenOverwrite: idToken,
      })
      replace(window.location.pathname + '?subscribe=closed')
      setLoadingMessage('')
    } catch (error: any) {
      console.error(error)
      setLoadingMessage('')
      setError(errorCodeCheck(error.code).errorMessage)
    }
  }
  const onSubmit = formObject.handleSubmit(handleSignIn)

  return (
    <SubscriptionContainer onSubmit={onSubmit}>
      <Grid container spacing={3} justifyContent='center'>
        <Hidden smDown>
          <Grid item paddingRight='5%'>
            <ProducerKitsLogo size='lg' />
          </Grid>
        </Hidden>
        {error && (
          <Grid item xs={12}>
            <Typography color='error' align='center' gutterBottom>
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            autoComplete='email'
            label={tcommon<string>('email')}
            name='email'
            size='medium'
            formObject={formObject}
            rules={{
              required: {
                value: true,
                message: tcommon<string>('required_field'),
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                message: tcommon<string>('rules_pattern_message'),
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={tcommon<string>('password')}
            name='password'
            type='password'
            size='medium'
            formObject={formObject}
            rules={{
              required: {
                value: true,
                message: tcommon<string>('required_field'),
              },
              minLength: {
                value: 6,
                message: tcommon<string>('rules_password_length_message'),
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ProducerKitsButton
            fullWidth
            white
            size='large'
            onClick={onSubmit}
            type='submit'
          >
            {tcommon<string>('log_in')}
          </ProducerKitsButton>
          <Typography pt={2} align='center'>
            {loadingMessage}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop='auto'>
          <Typography align='center'>
            Don't have an account?{' '}
            <LinkClick color='#ffffff' onClick={() => onNavigate('plans')}>
              Sign up here
            </LinkClick>
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop='auto'>
          <Typography align='center'>
            Forget your password?{' '}
            <LinkClick
              color='#ffffff'
              onClick={() => onNavigate('passwordforget')}
            >
              Reset it here
            </LinkClick>
          </Typography>
        </Grid>
      </Grid>
    </SubscriptionContainer>
  )
}
