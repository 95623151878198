import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'
import capitalize from '@mui/material/utils/capitalize'
import Hidden from '@mui/material/Hidden'
import CircularProgress from '@mui/material/CircularProgress'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { ProducerKitsLogo } from 'components/ProducerKitsLogo'
import { PlanRadioGroup } from 'components/PlanRadioGroup'
import { SwitchToggle } from 'components/SwitchToggle'
import { useRouter } from 'next/router'
import { useDoc } from 'hooks/useDoc'
import { useActivePlan } from 'hooks/useActivePlan'
import { LinkClick } from 'components/LinkClick'
import { CancelPlanModal } from 'components/CancelPlanModal'
import { CouponCodeField } from 'containers/SubscribePage/components/CouponCodeField'
import { firebaseObject } from 'containers/firebaseObject'
import { CouponIcon } from 'icons/CouponIcon'
import { useAuth } from 'hooks/useAuth'
import { dataLayerPush } from 'containers/GoogleTracking/utils'

export type PlanTypes = 'basic' | 'premium' | 'pro'
export type PeriodTypes = 'month' | 'year'
export const SubscriptionContainer = styled('form')(({ theme }) => ({
  minHeight: '700px',
  padding: '48px',
  [theme.breakpoints.down('lg')]: {
    padding: '32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}))

function castToType<T>(x: any, values: readonly T[], d: T): T {
  return values.includes(x) ? x : d
}

const multiplyYearly = (credits: number, period: string) =>
  period === 'year' ? credits * 12 : credits

export const PlansForm = () => {
  const { replace, query } = useRouter()
  const { plan, period, path } = query
  const planTypesArr = ['basic', 'premium', 'pro']
  const periodTypesArr = ['month', 'year'] as const
  const defaultPlan = {
    label: 'Premium',
    value: 'premium',
  }
  const hasPlanParam = typeof plan === 'string' && planTypesArr.includes(plan)
  const planValue = {
    label: hasPlanParam ? capitalize(plan) : defaultPlan.label,
    value: hasPlanParam ? plan : defaultPlan.value,
  }

  const periodValue = castToType(period, periodTypesArr, 'month')
  const [planSelected, setPlanSelected] = useState(planValue)
  const [periodSelected, setPeriodSelected] = useState(periodValue)
  const [openCancelPlanModal, setOpenCancelPlanModal] = useState(false)

  const [validCoupon, setValidCoupon] = useState('')
  const [addCoupon, setAddCoupon] = useState(false)
  const { user } = useAuth()

  const { data: plans, loading } = useDoc({
    collection: 'plans',
    id: 'plansData',
  })
  const { data: providerPlans } = useDoc({
    collection: 'plans',
    id: 'providerPlans',
  })
  const { activePlan, subscription } = useActivePlan({ providerPlans })
  const [currency] = useState<string>('USD')

  const plansOptions = plans
    ? Object.entries(plans)
        .sort()
        .map(([planKey, value]: any) => ({
          label: value?.product
            ? capitalize(value.product) +
              ' - ' +
              (currency === 'EUR' ? '€' : currency === 'USD' ? '$' : 'D') +
              value.periodPrices[periodSelected][currency] +
              '/' +
              periodSelected
            : '',
          value: planKey,
        }))
        .filter(plan => plan.label)
    : []

  const currentIsDefault =
    activePlan?.product === planSelected.value &&
    activePlan?.product === defaultPlan.value &&
    activePlan?.period === periodSelected

  const handleSubmit = () => {
    dataLayerPush('choose_plan', {
      periodSelected: periodSelected,
      planSelected: planSelected.label,
      email: user?.email || 'NO_USER', // email address string OR NO_USER
    })
    replace(
      {
        query: {
          ...query,
          subscribe: 'signup',
          plan: planSelected.value,
          period: periodSelected,
          ...(validCoupon ? { coupon: validCoupon } : {}),
        },
      },
      undefined,
      { scroll: false },
    )
  }

  return (
    <SubscriptionContainer onSubmit={handleSubmit}>
      {openCancelPlanModal && (
        <CancelPlanModal
          subscription={subscription}
          onClose={() => setOpenCancelPlanModal(false)}
        />
      )}
      <Grid container spacing={3} justifyContent='center'>
        <Hidden mdDown>
          <Grid item paddingRight='5%'>
            <ProducerKitsLogo size='lg' />
          </Grid>
        </Hidden>
        <Grid item paddingBottom='8px'>
          <SwitchToggle
            value={periodSelected === 'year'}
            onChange={() =>
              setPeriodSelected(periodSelected === 'year' ? 'month' : 'year')
            }
            labelLeft='Bill Monthly'
            labelRight='Bill Annually'
          />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <Typography variant='h4' align='center'>
              Loading Subscriptions
            </Typography>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <PlanRadioGroup
                activePlan={activePlan}
                planSelected={planSelected}
                periodSelected={periodSelected}
                onChange={setPlanSelected}
                options={plansOptions}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant='h4'>{`${multiplyYearly(
                plans?.[planSelected.value]?.credits,
                periodSelected,
              )} ${
                periodSelected === 'year' ? 'Yearly' : 'Monthly'
              } Credits`}</Typography>
              <Typography variant='subtitle2' color='textSecondary'>
                {`${multiplyYearly(
                  plans?.[planSelected.value]?.credits,
                  periodSelected,
                )} Downloads Per ${capitalize(periodSelected)}`}
              </Typography>
            </Grid>
            <Grid item marginLeft={'auto'}>
              <ProducerKitsButton
                onClick={() => setAddCoupon(!addCoupon)}
                size='small'
                variant='contained'
                sx={{
                  position: 'absolut',
                  width: '120px',
                  borderRadius: '5px',
                  right: '5px',
                  marginTop: '8px',
                }}
              >
                <CouponIcon style={{ padding: '5px' }} />
                <Typography>Coupon</Typography>
              </ProducerKitsButton>
            </Grid>
            {addCoupon && (
              <Grid item xs={12}>
                <CouponCodeField
                  setValidCoupon={value => setValidCoupon(value)}
                  firebaseObject={firebaseObject}
                  uid={user?.uid}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ProducerKitsButton
                size='large'
                white
                fullWidth
                disabled={currentIsDefault}
                onClick={handleSubmit}
              >
                Choose Plan
              </ProducerKitsButton>
              {activePlan && path === 'account' && (
                <Box
                  sx={{ width: '100%', textAlign: 'center', marginTop: '24px' }}
                >
                  <LinkClick
                    onClick={() => setOpenCancelPlanModal(true)}
                    align='center'
                    paddingTop='24px'
                  >
                    Cancel Subscription
                  </LinkClick>
                </Box>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </SubscriptionContainer>
  )
}
