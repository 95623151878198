import { ProviderPlan_SS } from '@fivano/models'
import { firebaseObject } from 'containers/firebaseObject'
import { getDocs, query, collection, where } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { errorLogger } from 'utils/errorLogger'
import { useAuth } from './useAuth'

export const useActivePlan = ({
  providerPlans,
}: {
  providerPlans?: { [key: string]: ProviderPlan_SS }
}) => {
  const { user } = useAuth()
  const [activePlan, setActivePlan] = useState<ProviderPlan_SS | null>(null)
  const [subscription, setSubscription] = useState<any>(null)
  useEffect(() => {
    if (user?.uid && providerPlans) {
      /** Get activePlan if there is an activePlan */
      getDocs(
        query(
          collection(firebaseObject.db, 'subscriptions'),
          where('status', '==', 'ACTIVE'),
          where('uid', '==', user.uid),
        ),
      )
        .then(response =>
          response?.docs.forEach(async doc => {
            const subscription = doc.data()
            if (subscription?.status === 'ACTIVE') {
              const planData = providerPlans?.[subscription?.providerPlanID]
              // @ts-ignore is of type PlanTypes
              setActivePlan(planData)
              setSubscription(subscription)
            }
          }),
        )
        .catch(errorLogger)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, providerPlans])
  return { activePlan, subscription }
}
