import React from 'react'
import MaterialCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'

import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

type CheckboxProps = {
  /**Visible label.*/
  label?: any
  /**Name of the Checkbox.*/
  name: string
  /**Object with all the form methods from react-hook-form.*/
  formObject: UseFormReturn<any>
  /**Rules that the component needs to abide to.*/
  rules?: RegisterOptions
  /**Name for the styling class.*/
  classNameCheckbox?: string
  /**Name for the styling class for the FormControlLabel.*/
  classNameLabel?: string
  /**Sets the size of the component.*/
  size?: 'medium' | 'small' | undefined
  /**Sets the component disabled or not.*/
  disabled?: boolean
  /** Color of the checkbox */
  color?: 'primary' | 'secondary' | 'default' | undefined
  /** Default value of the checkbox. */
  defaultValue?: boolean
  /** Callback fired when value is changed */
  onChange?: (value: boolean) => void
}

export const Checkbox = ({
  label,
  name,
  formObject,
  rules,
  classNameCheckbox,
  size = 'medium',
  classNameLabel,
  disabled = false,
  color,
  defaultValue = false,
  onChange,
}: CheckboxProps) => (
  <>
    <FormControlLabel
      label={rules?.required ? `${label} *` : label}
      disabled={disabled}
      className={classNameLabel}
      control={
        <Controller
          name={name}
          control={formObject.control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field: { onChange: onChangeRHF, value } }) => (
            <MaterialCheckbox
              name={name}
              color={color || 'primary'}
              onChange={e => {
                onChangeRHF(e.target.checked)
                onChange && onChange(e.target.checked)
              }}
              checked={value}
              className={classNameCheckbox}
              size={size}
              disabled={disabled}
            />
          )}
        />
      }
    />
    <FormHelperText error>
      <ErrorMessage name={name} errors={formObject.formState.errors} />
    </FormHelperText>
  </>
)
