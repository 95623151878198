import React, { useState } from 'react'

const area = 24
const timing = 'cubic-bezier(0, 0, 0, 1)'

export const Burger = ({
  color = 'currentColor',
  direction = 'left',
  distance = 'md',
  duration = 0.4,
  hideOutline = true,
  label,
  onToggle,
  render,
  rounded = false,
  size = 32,
  toggle,
  toggled,
  _lines = 3,
}: any) => {
  const [toggledInternal, toggleInternal] = useState(false)

  const width = Math.max(12, Math.min(area, size))
  const room = Math.round((area - width) / 2)

  const barHeightRaw = width / 12
  const barHeight = Math.round(barHeightRaw)

  const space = distance === 'lg' ? 0.25 : distance === 'sm' ? 0.75 : 0.5
  const marginRaw = width / (_lines * (space + (_lines === 3 ? 1 : 1.25)))
  const margin = Math.round(marginRaw)

  const height = barHeight * _lines + margin * (_lines - 1)
  const topOffset = Math.round((area - height) / 2)

  const translate =
    _lines === 3
      ? distance === 'lg'
        ? 4.0425
        : distance === 'sm'
        ? 5.1625
        : 4.6325
      : distance === 'lg'
      ? 6.7875
      : distance === 'sm'
      ? 8.4875
      : 7.6675
  const deviation =
    (barHeightRaw - barHeight + (marginRaw - margin)) / (_lines === 3 ? 1 : 2)
  const move = parseFloat((width / translate - deviation / (4 / 3)).toFixed(2))
  const time = Math.max(0, duration)

  const burgerStyles: any = {
    cursor: 'pointer',
    height: `${area}px`,
    position: 'relative',
    transition: `${time}s ${timing}`,
    userSelect: 'none',
    width: `${area}px`,
  }

  const barStyles: any = {
    background: color,
    height: `${barHeight}px`,
    left: `${room}px`,
    position: 'absolute',
    width: `${width}px`,
  }

  if (hideOutline) {
    burgerStyles['outline'] = 'none'
  }

  if (rounded) {
    barStyles['borderRadius'] = '9em'
  }

  const isExternal =
    typeof toggle === 'function' && typeof toggled === 'boolean'
  const isInternal =
    typeof toggle !== 'function' && typeof toggled !== 'boolean'

  const toggleFunction = isExternal
    ? toggle
    : isInternal
    ? toggleInternal
    : undefined
  const toggleCallback =
    typeof onToggle === 'function' && (isExternal || isInternal)
      ? onToggle
      : undefined

  const isToggled = typeof toggled === 'boolean' ? toggled : toggledInternal

  const handler = () => {
    if (toggleCallback) {
      toggleCallback(!isToggled)
    }

    if (toggleFunction) {
      toggleFunction(!isToggled)
    }
  }

  return render({
    barHeight,
    barStyles,
    burgerStyles,
    handler,
    isLeft: direction === 'left',
    isToggled,
    label,
    margin,
    move,
    time,
    timing,
    topOffset,
  })
}

export const HamburgerMenu = (props: any) => (
  <Burger
    {...props}
    render={(o: any) => (
      <div
        className='hamburger-react'
        aria-label={o.label}
        data-testid='tilt'
        onClick={o.handler}
        onKeyUp={e => (e.key === 'Enter' || e.keyCode === 13) && o.handler()}
        role='button'
        style={{
          ...o.burgerStyles,
          transform: `${
            o.isToggled ? `rotate(${90 * (o.isLeft ? -1 : 1)}deg)` : 'none'
          }`,
        }}
        tabIndex={0}
      >
        <div
          data-testid='bar-one'
          style={{
            ...o.barStyles,
            top: `${o.topOffset}px`,
            transition: `${o.time}s ${o.timing}`,
            transform: `${
              o.isToggled
                ? `rotate(${45 * (o.isLeft ? -1 : 1)}deg) translate(${
                    o.move * (o.isLeft ? -1 : 1)
                  }px, ${o.move}px)`
                : 'none'
            }`,
          }}
        />

        <div
          data-testid='bar-two'
          style={{
            ...o.barStyles,
            top: `${o.topOffset + o.barHeight + o.margin}px`,
            transition: `${o.time}s ${o.timing}`,
            transform: `${o.isToggled ? 'scaleX(0)' : 'none'}`,
          }}
        />

        <div
          data-testid='bar-three'
          style={{
            ...o.barStyles,
            top: `${o.topOffset + o.barHeight * 2 + o.margin * 2}px`,
            transition: `${o.time}s ${o.timing}`,
            transform: `${
              o.isToggled
                ? `rotate(${45 * (o.isLeft ? 1 : -1)}deg) translate(${
                    o.move * (o.isLeft ? -1 : 1)
                  }px, ${o.move * -1}px)`
                : 'none'
            }`,
          }}
        />
      </div>
    )}
  />
)
