import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { firebaseObject } from 'containers/firebaseObject'
import { useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { errorLogger } from 'utils/errorLogger'
import { convertToDate } from 'utils/convertToDate'

export const usePlanCheck = () => {
  const { user } = useAuth()
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)

  const subscriptionValid = subscription => {
    const duration = subscription.period === 'year' ? 365 : 31
    const validUntil = new Date(convertToDate(subscription.startedAt))
    validUntil.setDate(
      validUntil.getDate() + subscription.billingCyclesCompleted * duration,
    )
    return new Date() <= validUntil
  }

  useEffect(() => {
    if (user?.uid) {
      onSnapshot(
        query(
          collection(firebaseObject.db, 'subscriptions'),
          where('status', '==', 'ACTIVE'),
          where('uid', '==', user?.uid || 'NO_UID'),
          limit(1),
        ),
        /** onNext */
        querySnapshot => {
          if (querySnapshot.empty) {
            onSnapshot(
              query(
                collection(firebaseObject.db, 'subscriptions'),
                where('status', 'in', ['CANCELED', 'CANCELLED']),
                where('uid', '==', user?.uid || 'NO_UID'),
                limit(1),
                orderBy('startedAt', 'desc'),
              ),
              querySnapshot => {
                if (querySnapshot.empty) {
                  setData('NO_PLAN')
                } else {
                  const docs: any[] = []
                  querySnapshot.forEach(doc => {
                    // @ts-ignore
                    if (subscriptionValid(doc.data())) {
                      docs.push(doc.data())
                    }
                  })
                  setData(docs[0])
                }
              },
            )
          } else {
            const docs: any[] = []
            querySnapshot.forEach(doc => {
              // @ts-ignore
              docs.push(doc.data())
            })
            setData(docs[0])
          }
          setLoading(false)
        },
        /** onError */
        error => {
          errorLogger(error)
          setLoading(false)
        },
        /** onCompletion */
        () => {
          setLoading(false)
        },
      )
    }
  }, [user?.uid])
  return {
    data: data,
    loading,
  }
}
