import { getApp, initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import {
  browserLocalPersistence,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'
import { config } from 'configs/config'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

const isEmulating = config.firebaseEmulator

if (!(config.environment !== 'production')) {
  console.info(
    `==== INITIALIZED FIREBASE ${isEmulating ? 'EMULATOR' : 'CLOUD'}: ${
      config.firebaseConfig.projectId
    } ====`,
    config,
  )
}

const fb = initializeApp(config.firebaseConfig)
const db = getFirestore()
const app = getApp()
const storage = getStorage()

// const auth = getAuth() // THIS LOADS LARGE auth/iframe.js WHICH INCREASES BUNDLE SIZE
// initializeAuth throws an error if it's called more than once for the same app; save the reference.
const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
})
const EMULATORS_STARTED = 'EMULATORS_STARTED'
function startEmulators() {
  if (!global[EMULATORS_STARTED]) {
    global[EMULATORS_STARTED] = true
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectAuthEmulator(auth, 'http://localhost:9099/')
    connectStorageEmulator(storage, 'localhost', 9199)
  }
}

if (isEmulating) startEmulators()

export const firebaseObject = {
  fb,
  db,
  auth,
  storage,
}
