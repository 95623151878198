import { useEffect, useState } from 'react'
import { firebaseObject } from 'containers/firebaseObject'
import { useStore } from './useStore'

export const useAuth = () => {
  const [store, setStore] = useStore()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    if (store.user) {
      setLoading(false)
      return
    }
    const unsubscribe = firebaseObject.auth.onAuthStateChanged(user => {
      if (user) {
        setStore({ ...store, user })
      }
      setLoading(false)
    })
    return () => unsubscribe()
  }, [store, setStore])

  return { user: store.user, auth: firebaseObject.auth, loading }
}
