import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { errorCodeCheck } from 'utils/errorCodeCheck'
import { errorLogger } from 'utils/errorLogger'
import { useTranslation } from 'react-i18next'
import { PayPalButtons, FUNDING } from '@paypal/react-paypal-js'
import { useRouter } from 'next/router'
import { dataLayerPush } from 'containers/GoogleTracking/utils'
import { useAuth } from 'hooks/useAuth'

type PayPalCheckoutProps = {
  openSnack: (any) => void
  createSubscription: any
  providerPlanID: string
}

export const PayPalCheckout = ({
  openSnack,
  createSubscription,
  providerPlanID,
}: PayPalCheckoutProps) => {
  const { t: taccount } = useTranslation('account')
  const { push } = useRouter()
  const { user } = useAuth()
  const [paypalError, setPaypalError] = useState('')

  const paypalOnError = error => {
    const defaultMessage =
      'We could not process your payment, please try again.'
    let message = defaultMessage
    if (error.message === 'Invalid form') {
      message =
        'Form is invalid. Please enter all values correctly and try again.'
    } else if (error.message.substring(0, 13) === 'FirebaseError') {
      const errorCode = error.message.substring(
        error.message.indexOf('(') + 1,
        error.message.lastIndexOf(')'),
      )
      message = errorCodeCheck(errorCode).errorMessage
    } else if (message === defaultMessage) {
      errorLogger(error, 'paypal')
    }
    if (message !== defaultMessage) {
      openSnack({
        message,
        severity: 'error',
      })
    }
  }
  const paypalOnCancel = error => {
    errorLogger(error, 'paypal')
    setPaypalError(taccount<string>('payment_cancelled_message'))
    openSnack({
      message: taccount<string>('payment_cancelled_message'),
      severity: 'error',
    })
  }
  return (
    <>
      <PayPalButtons
        key={providerPlanID}
        disabled={!providerPlanID}
        className='producerkits-paypal'
        // @ts-ignore
        fundingSource={FUNDING.PAYPAL}
        createSubscription={createSubscription}
        onClick={async () => {
          dataLayerPush('pay_paypal', {
            data: user || 'UNKNOWN_USER',
          })
        }}
        onApprove={async () => {
          dataLayerPush('sign_up_paypal_success', {
            data: user || 'UNKNOWN_USER',
          })
          push(
            window.location.pathname !== '/'
              ? window.location.pathname +
                  '?show=subscribe-success&subscribe=closed'
              : '/explore?show=subscribe-success&subscribe=closed',
          )
        }}
        onError={paypalOnError}
        onCancel={paypalOnCancel}
        style={{
          color: 'white',
          label: 'paypal',
          height: 50,
        }}
      />

      <Typography align='center' color='error'>
        {paypalError}
      </Typography>
    </>
  )
}
