export const navigationMenu = (isAuthenticated: boolean) => [
  {
    label: 'My Account',
    path: '/account?path=account',
    showDesktop: false,
    showDesktopHeaderMenu: true,
    showMobileMenu: isAuthenticated,
  },
  {
    label: 'Subscribe',
    path: '/account?path=account&subscribe=plans',
    showDesktop: false,
    showDesktopHeaderMenu: true,
    showMobileMenu: false,
  },
  {
    label: 'Favorites',
    path: '/account?path=favorites',
    showDesktop: false,
    showDesktopHeaderMenu: true,
    showMobileMenu: false,
  },
  {
    label: 'Downloads',
    path: '/account?path=downloads',
    showDesktop: false,
    showDesktopHeaderMenu: true,
    showMobileMenu: false,
  },
  {
    label: 'Billing',
    path: '/account?path=billing',
    showDesktop: false,
    showDesktopHeaderMenu: true,
    showMobileMenu: false,
  },
]
