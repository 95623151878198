export const errorCodeCheck = (errorCode: string) => {
  let errorMessage = 'Unknown error, please contact us to resolve this issue'
  let toSentry: boolean = true
  switch (errorCode) {
    case 'auth/email-already-in-use':
      errorMessage =
        'This email address is already in use. Use another email address or log in'
      toSentry = false
      break
    case 'auth/email-already-exists':
      errorMessage =
        'This email address is already in use. Use another email address or log in'
      toSentry = false
      break
    case 'auth/invalid-email':
      errorMessage = 'Invalid email address, please use a valid email address'
      toSentry = false
      break
    case 'auth/operation-not-allowed':
      errorMessage =
        'Registering with email and password is not possible at the moment, please contact the system administrator to enable email and password registration '
      toSentry = true
      break
    case 'auth/weak-password':
      errorMessage =
        'The password you chose is not strong enough, please choose a more secure password'
      toSentry = false
      break
    case 'auth/user-disabled':
      errorMessage =
        'Your account has been disabled by the administration of this organisation, please contact us to resolve this issue'
      toSentry = true
      break
    case 'auth/user-not-found':
      errorMessage =
        'There is no account under the username you entered, please login with a valid username. It is most likely an email address'
      toSentry = false
      break
    case 'auth/email-not-found':
      errorMessage =
        'There is no user record corresponding to the provided email.'
      toSentry = false
      break
    case 'auth/wrong-password':
      errorMessage =
        'Your password is incorrect, please use the correct password for this username'
      toSentry = false
      break
    case 'credits-already-withdrawn':
      errorMessage =
        'Credits have already been withdrawn for this music track and file'
      toSentry = false
      break
    case 'no-active-subscription':
      errorMessage = 'You do not have an active subscription, please subscribe'
      toSentry = false
      break
  }
  return { errorMessage, toSentry }
}
