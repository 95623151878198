import styled from '@mui/material/styles/styled'

export const TopSpacing = styled('div')(({ theme }) => ({
  width: '100%',
  height: 160,
  [theme.breakpoints.down('xl')]: {
    height: 140,
  },
  [theme.breakpoints.down('md')]: {
    height: 100,
  },
  [theme.breakpoints.down('sm')]: {
    height: 80,
  },
}))
