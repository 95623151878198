import { useEffect, useState } from 'react'
import { firebaseObject } from 'containers/firebaseObject'
import { doc, getDoc as getDocFB } from 'firebase/firestore'
import { errorLogger } from 'utils/errorLogger'

export type useDocProps = {
  collection: string
  id?: string
}

export const getDoc = ({ collection, id }) =>
  getDocFB(doc(firebaseObject.db, collection, id))

export const useDoc = ({ collection, id }: useDocProps) => {
  const [docData, setDocData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    if (!id) return
    getDoc({ collection, id })
      .then(doc => {
        setLoading(false)
        setDocData({ ...doc.data(), id: doc.id })
      })
      .catch(error => {
        setLoading(false)
        errorLogger(error)
      })
  }, [collection, id])
  return { data: docData, loading }
}
