import { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import styled from '@mui/material/styles/styled'
import { TextField } from 'components/TextField'
import { Checkbox } from 'components/Checkbox'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { firebaseObject } from 'containers/firebaseObject'
import { useTranslation } from 'react-i18next'
import { errorLogger } from 'utils/errorLogger'
import { dataLayerPush } from 'containers/GoogleTracking/utils'
import { useFunctionRequest } from 'hooks/useFunctionRequest'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useSnackbar } from 'components/useSnackbar'
import { ProducerKitsLogo } from 'components/ProducerKitsLogo'
import { LinkClick } from 'components/LinkClick'
import { AutoSelect } from 'components/AutoSelect'
import { Switch } from 'components/Switch'
import { Person_SS, Subscription_SS } from '@fivano/models'
import { useAuth } from 'hooks/useAuth'
import PaymentIcon from '@mui/icons-material/Payment'
import { PayPalCheckout } from 'containers/SubscribePage/PayPalCheckout'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { config } from 'configs/config'
import { useDocListener } from 'hooks/useDocListener'
import CircularProgress from '@mui/material/CircularProgress'
import { useRouter } from 'next/router'
import { SubscribeContentForms } from 'types/types'
import { useForm } from 'react-hook-form'
import { doc, updateDoc } from 'firebase/firestore'

export const SubscriptionContainer = styled('form')(({ theme }) => ({
  minHeight: '700px',
  padding: '16px 48px 24px 48px',
  [theme.breakpoints.down('lg')]: {
    padding: '32px',
    minHeight: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '200px',
    padding: '24px 20px',
  },
}))

type Option_SS = {
  value: string
  label: string
}

type SignUpFormProps = {
  countries: Option_SS[]
  onNavigate: (form: SubscribeContentForms) => void
  providerPlanID: string
  validCoupon?: string
  setCountryCode: (countryCode: string) => void
}

type SignUpFormType = {
  firstNamePers: string
  lastNamePers: string
  emailPers: string
  password: string
  country: Option_SS
  receiveNewsletter: boolean
  agreePolicy?: boolean
  isCompany?: boolean
  companyName?: string
  VATNumber?: string
}

export const SignUpForm = ({
  countries,
  onNavigate,
  providerPlanID,
  validCoupon,
  setCountryCode,
}: SignUpFormProps) => {
  const { user } = useAuth()
  const { data: person, loading: loadingPerson } = useDocListener({
    collection: 'persons',
    id: user?.uid,
  })
  return (
    <SignUpFormInputs
      person={person}
      loadingPerson={loadingPerson}
      countries={countries}
      onNavigate={onNavigate}
      providerPlanID={providerPlanID}
      validCoupon={validCoupon}
      setCountryCode={setCountryCode}
    />
  )
}

export const SignUpFormInputs = ({
  countries,
  onNavigate,
  providerPlanID,
  validCoupon,
  setCountryCode,
  person,
  loadingPerson,
}: SignUpFormProps & { person: Person_SS; loadingPerson: boolean }) => {
  const personFlattened = {
    ...person,
    ...(person?.addressesPers?.[0] ? person?.addressesPers?.[0] : {}),
  }
  const formObject = useForm<SignUpFormType>({ defaultValues: personFlattened })
  const { openSnack } = useSnackbar()
  const { push } = useRouter()
  const { t: tcommon } = useTranslation('common')
  const { t: taccount } = useTranslation('account')
  const makeRequest = useFunctionRequest()

  const [updateFields, setUpdateFields] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingMessage, setLoadingMessage] = useState('')
  const [stripeErrorMessage, setStripeErrorMessage] = useState('')
  const isSignedIn = Boolean(person?.emailPers)

  useEffect(() => {
    const personFlattened = {
      ...person,
      ...(person?.addressesPers?.[0] ? person?.addressesPers?.[0] : {}),
    }
    if (isSignedIn) {
      formObject.reset(personFlattened)
      setUpdateFields(value => value + 1)
    }
  }, [formObject, isSignedIn, person])

  useEffect(() => {
    if (isSignedIn || loadingPerson) {
      if (personFlattened?.countryCode) {
        setCountryCode(personFlattened.countryCode)
      }
      return
    }
    fetch('https://api.country.is').then(async response => {
      const countryData = await response.json()
      const currentLocation = countries.find(
        country => country.value === countryData.country,
      )
      if (currentLocation) {
        setCountryCode(countryData.country)
        formObject.setValue('country', currentLocation)
      }
    })
  }, [
    countries,
    formObject,
    setCountryCode,
    isSignedIn,
    loadingPerson,
    personFlattened.countryCode,
  ])

  const getOrCreateUser = useCallback(
    async (data: SignUpFormType) => {
      const auth = getAuth()
      try {
        if (!auth?.currentUser?.uid) {
          setLoadingMessage(
            'Please wait while we check your email and create your account',
          )
          await makeRequest({
            functionName: 'signUp',
            body: data,
            type: 'public',
            showResponse: false,
          })
          dataLayerPush('sign_up_email', {
            receiveNewsletter: data.receiveNewsletter, // true or false
            firstName: data.firstNamePers, // string
            lastName: data.lastNamePers, // string
            email: data.emailPers, // string
            createdAt: new Date(), // Javascript Date (ISODateString?)
            source: 'create-account-website',
          })
          const auth = await signInWithEmailAndPassword(
            firebaseObject.auth,
            data.emailPers,
            data.password,
          )
          const idToken = await auth.user.getIdToken()
          setLoadingMessage('')
          return {
            idToken,
          }
        } else {
          const currentUser = firebaseObject.auth.currentUser
          if (!currentUser) throw new Error('No currentUser data')
          const idToken = await currentUser.getIdToken()
          const uid = currentUser.uid
          await updateDoc(
            doc(firebaseObject.db, 'persons', uid),
            createPersonDoc(data),
          )
          return { idToken }
        }
      } catch (error: any) {
        setLoadingMessage('')
        errorLogger(error)
        openSnack({ message: error.message, severity: 'error' })
        setErrorMessage(error.message)
      }
    },
    [makeRequest, openSnack],
  )

  const createStripeSubscription = useCallback(
    async (data: SignUpFormType) => {
      /** Form is validated by handleSubmit of: formObject.handleSubmit(createStripeSubscription) */
      setLoadingMessage('Please wait until redirected to the checkout-page')
      /** Check if a user exists, if no user create a user */
      const userData = await getOrCreateUser(data)
      if (!userData) {
        throw new Error('No user data')
      }
      const idToken = userData?.idToken

      const subscription: {
        redirectUrl?: any
        subscriptionData: Subscription_SS
      } = await makeRequest({
        functionName: 'createSubscriptionAtProvider',
        body: {
          providerPlanID,
          provider: 'stripe',
          couponCodeID: validCoupon,
          redirectSucces:
            window.location.origin +
            (window.location.pathname !== '/'
              ? window.location.pathname
              : '/explore'),
          redirectFail: window.location.href,
        },
        ...(idToken ? { idTokenOverwrite: idToken } : {}),
        showResponse: false,
      }).catch(error => {
        errorLogger(error)
        setLoadingMessage('')
        openSnack({ message: error.message, severity: 'error' })
      })

      dataLayerPush('pay_stripe', {
        data: { email: data.emailPers },
      })
      setLoadingMessage('')

      if (subscription?.redirectUrl) {
        push(subscription.redirectUrl)
      }
    },
    [
      getOrCreateUser,
      makeRequest,
      providerPlanID,
      validCoupon,
      openSnack,
      push,
    ],
  )

  /** Promise creating subscription data with Stripe of Paypal provider */
  const createPaypalSubscription = useCallback(
    async () =>
      await formObject.trigger().then(async formIsValid => {
        /** Check if form is valid */
        if (!formIsValid) throw new Error('Invalid form')

        setLoadingMessage('Please wait until redirected to the checkout-page')

        /** Check if a user exists, if no user create a user */
        const formData: SignUpFormType = formObject.getValues()
        const userData = await getOrCreateUser(formData)
        if (!userData) {
          throw new Error('No user data')
        }
        const idToken = userData.idToken

        const subscription: {
          clientSecret?: any
          subscriptionData: Subscription_SS
          subscriptionID: string
        } = await makeRequest({
          functionName: 'createSubscriptionAtProvider',
          body: {
            providerPlanID,
            provider: 'paypal',
            couponCodeID: validCoupon,
          },
          idTokenOverwrite: idToken,
          showResponse: false,
        }).catch(error => {
          errorLogger(error)
          setLoadingMessage('')
          openSnack({ message: error.message, severity: 'error' })
        })
        setLoadingMessage('')
        return subscription
      }),
    [
      formObject,
      getOrCreateUser,
      makeRequest,
      providerPlanID,
      validCoupon,
      openSnack,
    ],
  )

  const isCompany = formObject.watch('isCompany')

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    setStripeErrorMessage('')
    const query = new URLSearchParams(window.location.search)
    if (query.get('success')) {
      console.log('Order placed! You will receive an email confirmation.')
    }

    if (query.get('canceled')) {
      console.log(
        'Order canceled -- continue to shop around and checkout when you’re ready.',
      )
      setStripeErrorMessage(taccount<string>('payment_cancelled_message'))
    }
  }, [taccount])

  return (
    <SubscriptionContainer>
      <Grid container spacing={1.5} justifyContent='center'>
        <Hidden smDown>
          <Grid item paddingRight='5%'>
            <ProducerKitsLogo size='lg' />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6}>
          <TextField
            name='firstNamePers'
            key={`firstNamePers${updateFields}`}
            label={taccount<string>('firstname_text')}
            size='medium'
            formObject={formObject}
            rules={{
              required: {
                value: true,
                message: tcommon<string>('required_field'),
              },
            }}
            // disabled={isSignedIn}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name='lastNamePers'
            key={`lastNamePers${updateFields}`}
            label={taccount<string>('lastname_text')}
            size='medium'
            formObject={formObject}
            rules={{
              required: {
                value: true,
                message: tcommon<string>('required_field'),
              },
            }}
            // disabled={isSignedIn}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='emailPers'
            key={`emailPers${updateFields}`}
            label={tcommon<string>('email')}
            size='medium'
            formObject={formObject}
            rules={{
              required: {
                value: true,
                message: tcommon<string>('required_field'),
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                message: taccount<string>('email_invalid_text'),
              },
            }}
            disabled={isSignedIn}
          />
        </Grid>

        {!isSignedIn && (
          <Grid item xs={12}>
            <TextField
              label={tcommon<string>('password')}
              name='password'
              size='medium'
              formObject={formObject}
              rules={{
                required: {
                  value: true,
                  message: tcommon<string>('required_field'),
                },
                minLength: {
                  value: 6,
                  message: tcommon<string>('rules_password_length_message'),
                },
              }}
              InputProps={{ type: 'password' }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            position='relative'
            sx={{
              '& .MuiOutlinedInput-root, .MuiAutocomplete-input, input': {
                padding: '9px 8px !important',
              },
            }}
          >
            <AutoSelect
              label={taccount<string>('country_text')}
              name='country'
              formObject={formObject}
              options={countries}
              onChange={(_, country) => setCountryCode(country?.value)}
              rules={{
                required: {
                  value: true,
                  message: tcommon<string>('required_field'),
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box paddingLeft='16px'>
            <Switch
              name='isCompany'
              label={taccount<string>('company_text')}
              formObject={formObject}
              // disabled={isSignedIn}
            />
          </Box>
        </Grid>
        {isCompany && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label={`${taccount<string>('company_name_text')}`}
                name='companyName'
                key={`companyName${updateFields}`}
                formObject={formObject}
                rules={{
                  required: {
                    value: true,
                    message: tcommon<string>('required_field'),
                  },
                }}
                size='medium'
                // disabled={isSignedIn}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name='VATNumber'
                key={`VATNumber${updateFields}`}
                label={`${taccount<string>('company_vatnumber_text')}`}
                formObject={formObject}
                rules={{
                  required: {
                    value: true,
                    message: tcommon<string>('required_field'),
                  },
                }}
                size='medium'
                // disabled={isSignedIn}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} paddingBottom={2}>
          {!person?.receiveNewsletter && (
            <Checkbox
              name='receiveNewsletter'
              label={taccount<string>('checkbox_label_text')}
              formObject={formObject}
              // disabled={isSignedIn}
            />
          )}
          <Checkbox
            name='agreePolicy'
            label={taccount<string>('termsofuse_text')}
            formObject={formObject}
            rules={{
              required: {
                value: true,
                message: taccount<string>('termsofuse_rules_text'),
              },
            }}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align='center'>Choose a payment method</Typography>
          </Grid>
          <Grid item xs={6}>
            <ProducerKitsButton
              size='large'
              onClick={() => {
                formObject.handleSubmit(createStripeSubscription)()
              }}
              fullWidth
              style={{ borderRadius: '4px', color: 'white' }}
            >
              <PaymentIcon fontSize='large' color='inherit' />
              {'  '}Card
            </ProducerKitsButton>
            <Typography align='center' color='error'>
              {stripeErrorMessage}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <PayPalScriptProvider
              options={{
                'client-id': config.paypalClientID,
                intent: 'subscription',
                vault: true,
                components: 'marks,buttons',
                locale: 'en_US',
              }}
            >
              <PayPalCheckout
                openSnack={openSnack}
                createSubscription={async () => {
                  const paypalData = await createPaypalSubscription()
                  return paypalData.subscriptionID
                }}
                providerPlanID={providerPlanID}
              />
            </PayPalScriptProvider>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box pt={1}>
            <Typography gutterBottom align='center'>
              {loadingMessage && <CircularProgress />} {loadingMessage}
            </Typography>
            <Typography color='error' gutterBottom align='center'>
              {errorMessage}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            <LinkClick color='#ffffff' onClick={() => onNavigate('plans')}>
              Choose Different Subscription
            </LinkClick>
          </Typography>
        </Grid>
        {!isSignedIn && (
          <Grid item xs={12}>
            <Typography align='center'>
              Already a member?{' '}
              <LinkClick color='#ffffff' onClick={() => onNavigate('signin')}>
                Log in here
              </LinkClick>
            </Typography>
          </Grid>
        )}
      </Grid>
    </SubscriptionContainer>
  )
}

export const createPersonDoc = (data: SignUpFormType) => ({
  firstNamePers: data.firstNamePers,
  lastNamePers: data.lastNamePers,
  emailPers: data.emailPers,
  addressesPers: [
    {
      country: data.country,
      countryCode: data?.country?.value,
      isCompany: data.isCompany || false,
      companyName: data.companyName || '',
      VATNumber: data.VATNumber || '',
    },
  ],
  updatedAt: new Date(),
  fromDate: new Date(),
  archived: false,
})
