export const headerMenu = [
  {
    label: 'Home',
    path: '/',
    showDesktop: false,
    showDesktopHeaderMenu: false,
    showMobileMenu: true,
  },
  {
    label: 'Explore',
    path: '/explore',
    showDesktop: true,
    showDesktopHeaderMenu: false,
    showMobileMenu: true,
  },
  {
    label: 'Plans',
    path: '/pricing',
    showDesktop: true,
    showDesktopHeaderMenu: false,
    showMobileMenu: true,
  },
  {
    label: 'FAQ',
    path: '/frequently-asked-questions',
    showDesktop: true,
    showDesktopHeaderMenu: false,
    showMobileMenu: true,
  },
]
