import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { TextField } from 'components/TextField'
import { inputStyle } from 'data/inputStyle'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { validateCoupon } from '../validateCoupon'

export const CouponCodeField = ({ setValidCoupon, firebaseObject, uid }) => {
  const [couponMessage, setCouponMessage] = useState('')
  const formObject = useForm({ reValidateMode: 'onSubmit' })

  const couponRules = {
    minLength: {
      value: 4,
      message: 'Please enter a coupon with more than 4 characters',
    },
    validate: async value => {
      const { valid, message } = await validateCoupon(
        value,
        firebaseObject.db,
        uid,
      )
      return valid === false ? message : true
    },
  }

  const handleCoupon = () => {
    setCouponMessage('')
    formObject.handleSubmit(async data => {
      const couponCode = data?.couponCodeID
      const validation = await validateCoupon(
        couponCode,
        firebaseObject.db,
        uid,
      )
      if (validation.valid) {
        setCouponMessage(validation.message)
        formObject.clearErrors('couponCodeID')
        setValidCoupon(couponCode)
      } else {
        formObject.setError('couponCodeID', {
          type: 'custom',
          message: validation.message,
        })
        setCouponMessage(validation.message)
      }
    })()
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid
          item
          sx={{
            width: 'calc(100% - 150px)',
          }}
        >
          <TextField
            label='Coupon code'
            name='couponCodeID'
            formObject={formObject}
            rules={couponRules}
            {...inputStyle}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: '150px',
          }}
        >
          <ProducerKitsButton
            variant='outlined'
            white
            sx={{
              height: '56px',
              width: '160px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              marginLeft: '-10px',
              borderLeft: '2px solid #161A1A',
              borderColor: '#161A1A',
              color: '#161A1A',
              backgroundColor: '#EBEBEB',
              ':hover': {
                backgroundColor: '#EBEBEB',
                opacity: 1,
                color: 'grey',
                borderColor: 'grey',
              },
            }}
            onClick={handleCoupon}
          >
            Apply Coupon
          </ProducerKitsButton>
        </Grid>
        {couponMessage && (
          <Grid>
            <Typography align='center' color='#fb6064' variant='body2'>
              {couponMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
