import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { ProducerKitsIcon } from 'icons/ProducerKitsIcon'
type LogoSizes = 'md' | 'lg'
type ProducerKitsLogoProps = {
  size?: LogoSizes
}
const sizeMap: Record<LogoSizes, number> = {
  md: 48,
  lg: 96,
}
export const ProducerKitsLogo = ({ size = 'md' }: ProducerKitsLogoProps) => {
  const logoSize = sizeMap[size || 'md']
  const fontSize =
    size === 'lg' ? 'h2' : size === 'md' ? 'subtitle2' : 'subtitle2'
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <ProducerKitsIcon
        style={{
          marginTop: '-4px',
          minWidth: `${logoSize}px`,
          minHeight: `${logoSize}px`,
          width: `${logoSize}px`,
          height: `${logoSize}px`,
          color: 'inherit',
          fill: 'currentColor',
        }}
      />
      <Typography
        variant={fontSize}
        sx={{
          fontWeight: 'bold',
          minWidth: size === 'md' ? '140px' : size === 'lg' ? '240px' : 'auto',
          color: 'inherit',
          letterSpacing: -0.1,
          marginLeft: '8px',
          paddingTop: `${size === 'lg' ? 12 : 0}px`,
        }}
      >
        Producer Kits
      </Typography>
    </Stack>
  )
}
