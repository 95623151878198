export const CouponIcon = props => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    width='33.2000000pt'
    height='33.2000000pt'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
    {...props}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='#fff'
      stroke='none'
    >
      <path
        d='M223 4301 c-72 -24 -127 -65 -163 -120 -64 -96 -61 -1 -58 -1642 l3
-1494 26 -56 c33 -71 87 -125 158 -158 l56 -26 440 -3 c242 -2 456 0 475 3 27
5 62 33 158 128 l122 121 123 -121 c96 -95 130 -123 157 -127 19 -4 737 -5
1595 -4 l1560 3 56 26 c71 33 125 87 158 158 l26 56 0 1515 0 1515 -26 56
c-33 71 -87 125 -158 158 l-56 26 -1560 3 c-858 1 -1576 0 -1595 -4 -28 -4
-61 -32 -158 -127 l-122 -121 -118 116 c-64 64 -130 122 -145 127 -18 7 -184
11 -465 11 -394 -1 -442 -2 -489 -19z m1377 -781 l0 -160 -160 0 -160 0 0 160
0 160 160 0 160 0 0 -160z m1223 145 c91 -19 160 -57 233 -130 276 -273 122
-744 -263 -806 -314 -51 -595 230 -544 543 16 102 62 190 135 263 72 71 143
111 229 130 81 18 128 18 210 0z m1270 -18 c73 -56 86 -151 31 -224 -16 -21
-387 -468 -825 -994 -623 -748 -804 -959 -834 -973 -28 -13 -50 -17 -83 -12
-124 17 -182 154 -106 253 16 21 387 468 825 993 551 663 806 961 830 973 48
25 118 18 162 -16z m-2493 -767 l0 -160 -160 0 -160 0 0 160 0 160 160 0 160
0 0 -160z m0 -640 l0 -160 -160 0 -160 0 0 160 0 160 160 0 160 0 0 -160z
m2183 145 c91 -19 160 -57 233 -130 276 -273 122 -744 -263 -806 -314 -51
-595 230 -544 543 16 102 62 190 135 263 72 71 143 111 229 130 81 18 128 18
210 0z m-2183 -785 l0 -160 -160 0 -160 0 0 160 0 160 160 0 160 0 0 -160z'
      />
      <path
        d='M2655 3346 c-101 -44 -125 -178 -46 -257 112 -113 296 -12 267 146
-18 94 -131 151 -221 111z'
      />
      <path
        d='M3615 2066 c-101 -44 -125 -178 -46 -257 65 -65 157 -65 222 0 124
124 -15 327 -176 257z'
      />
    </g>
  </svg>
)
