import { firebaseObject } from 'containers/firebaseObject'
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  limit,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { CreditTransaction_SS } from '@fivano/models'

export const useCreditSaldo = () => {
  const { user } = useAuth()
  const [creditTransactionsData, setCreditTransactionsData] =
    useState<CreditTransaction_SS>()

  useEffect(() => {
    if (user?.uid) {
      onSnapshot(
        query(
          collection(firebaseObject.db, 'creditTransactions'),
          where('uid', '==', user?.uid),
          orderBy('createdAt', 'desc'),
          limit(1),
        ),
        querySnapshot => {
          const docs: CreditTransaction_SS[] = []
          querySnapshot.forEach(doc => {
            // @ts-ignore
            docs.push(doc.data())
          })
          setCreditTransactionsData(docs[0])
        },
      )
    }
  }, [user?.uid])

  if (creditTransactionsData) {
    return (
      creditTransactionsData.totalAddedOtherSubs +
      creditTransactionsData.newTotalAddedCurrentSub -
      creditTransactionsData.newTotalWithdrawn
    )
  }
  return 0
}
