import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import styled from '@mui/material/styles/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles/createTheme'
import { SignUpForm } from './SignUpForm'
import { SignInForm } from './SignInForm'
import { PasswordForgetForm } from './PasswordForgetForm'
import { PlansForm } from './PlansForm'
import { usePlans } from 'hooks/usePlans'
import capitalize from '@mui/material/utils/capitalize'
import Image from 'next/image'
import {
  optionType,
  PlanTypes,
  PeriodType,
  SubscribeContentForms,
} from 'types/types'
import { useRouter } from 'next/router'
import { getCountries } from 'data/getSortedCountries'
import { useEffect, useState } from 'react'
import { validateCoupon } from 'containers/SubscribePage/validateCoupon'
import { firebaseObject } from 'containers/firebaseObject'
import { useAuth } from 'hooks/useAuth'
import { dataLayerPush } from 'containers/GoogleTracking/utils'

const UIContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  height: '700px',
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },
}))
const ImagePositioner = styled('div')(() => ({
  position: 'absolute',
  top: 0,
}))
const FeatureContainer = styled(Grid)(({ theme }) => ({
  padding: '0px 32px 16px 32px',
  [theme.breakpoints.down('lg')]: {
    padding: '0px 16px 8px 16px',
  },
}))
const FeatureContent = styled(Grid)(({ theme }) => ({
  border: '2px solid white',
  borderRadius: '10px',
  ...theme.typography.body1,
  textAlign: 'center',
  whiteSpace: 'pre-line',
  padding: '8px',
}))
const FeatureInfoContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  padding: '40px 16px 32px 16px',
  bottom: 0,
  zIndex: 1,
  background:
    'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.66) 20%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0.9) 100%)',
  [theme.breakpoints.down('md')]: {
    padding: '40px 16px 8px 16px',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
  },
}))

type SubscribeContentProps = {
  countries: optionType[]
  activeForm: SubscribeContentForms
  setActiveForm: (form: SubscribeContentForms) => void
}
export const SubscribeContent = ({
  countries,
  activeForm,
  setActiveForm,
}: SubscribeContentProps) => {
  const { user } = useAuth()
  const { getPlanByParams } = usePlans()
  const { query } = useRouter()
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const product = query?.plan as PlanTypes
  const period = query?.period as PeriodType
  const couponCode = query?.coupon as string

  const [validatedCouponCode, setValidatedCouponCode] = useState('')
  const [couponMessage, setCouponMessage] = useState('')
  const [currency, setCurrency] = useState('')
  const [countryCode, setCountryCode] = useState<string | undefined>()

  const multiplyYearly = (credits: number, period: string) =>
    period === 'year' ? credits * 12 : credits

  const getCountryList = async () => {
    const list = await getCountries()
    return list
  }

  useEffect(() => {
    getCountryList().then(countryList => {
      let isEuropean =
        countryList &&
        Object.values(countryList)?.find(c => c.code === countryCode)?.european
      setCurrency(
        isEuropean === true ? 'EUR' : isEuropean === false ? 'USD' : '',
      )
    })
  }, [countryCode])

  const plans = getPlanByParams({ product, period, currency })

  useEffect(() => {
    if (couponCode) {
      const getCouponMessage = async () => {
        const validation = await validateCoupon(
          couponCode,
          firebaseObject.db,
          user ? user.uid : '',
        )
        setCouponMessage(validation.valid ? validation.message : '')
        setValidatedCouponCode(validation.valid ? couponCode : '')
      }
      getCouponMessage()
    }
  }, [couponCode, user])

  useEffect(() => {
    dataLayerPush('open_plan_modal', {
      email: user?.email || 'NO_USER', // email address string OR NO_USER
    })
  }, [user?.email])

  return (
    <Grid container>
      <Grid item xs={12} md={6} bgcolor='#000' overflow='hidden'>
        <UIContainer container>
          <ImagePositioner>
            <Image
              width={1474}
              height={1087}
              src='/tracks-collection.jpg'
              alt='producerkits tracks collection'
            />
          </ImagePositioner>
          <FeatureInfoContainer>
            {(activeForm === 'plans' ||
              (activeForm === 'signup' && (!query.plan || !query.period))) && (
              <>
                <Typography align='center' variant='h4'>
                  Subscribe To Download
                </Typography>
                <br />
                <Typography align='center' gutterBottom>
                  Unlock access to our ever-growing catalog.
                </Typography>
                <Typography align='center' gutterBottom>
                  Subscribe for just $9.99. Cancel anytime.
                </Typography>
              </>
            )}
            {activeForm === 'signup' && query.plan && query.period && (
              <>
                <Typography align='center' variant='h4'>
                  Chosen Subscription:
                </Typography>
                <br />
                {currency && plans && period && (
                  <>
                    <Typography align='center' gutterBottom variant='h6'>
                      {capitalize(plans?.product || '')}
                      {' - '}
                      {currency === 'EUR' ? '€' : '$'}
                      {parseFloat(plans?.periodPrice).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                      {'/'}
                      {capitalize(period)}
                    </Typography>
                    <Typography align='center' gutterBottom variant='h6'>
                      {`${multiplyYearly(plans?.credits, period)}`} Downloads
                      Per {capitalize(period)}
                    </Typography>
                  </>
                )}
                {couponMessage && (
                  <Typography align='center' gutterBottom variant='body2'>
                    {couponMessage}
                  </Typography>
                )}
              </>
            )}
            {(activeForm === 'signin' || activeForm === 'passwordforget') && (
              <>
                <Typography align='center' variant='h4'>
                  Welcome Back!
                </Typography>
                <br />
                <Typography align='center' gutterBottom>
                  It's great to see you again, which sound wil you
                </Typography>
                <Typography align='center' gutterBottom>
                  pick to start your track today?
                </Typography>
              </>
            )}
            <Hidden mdDown>
              <br />
              <br />
              <FeatureContainer container spacing={downLG ? 2 : 3}>
                <Grid item xs={4}>
                  <FeatureContent>{`100% \n Royalty-Free`}</FeatureContent>
                </Grid>
                <Grid item xs={4}>
                  <FeatureContent>{`Created By \n Top-Producers`}</FeatureContent>
                </Grid>
                <Grid item xs={4}>
                  <FeatureContent>{`Get Inspired \n Instantly`}</FeatureContent>
                </Grid>
              </FeatureContainer>
            </Hidden>
          </FeatureInfoContainer>
        </UIContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        {activeForm === 'plans' ? (
          <PlansForm />
        ) : activeForm === 'signup' && query.plan && query.period ? (
          <SignUpForm
            countries={countries}
            setCountryCode={setCountryCode}
            onNavigate={setActiveForm}
            providerPlanID={plans?.providerPlanID}
            validCoupon={validatedCouponCode}
          />
        ) : activeForm === 'signin' ? (
          <SignInForm onNavigate={setActiveForm} />
        ) : activeForm === 'passwordforget' ? (
          <PasswordForgetForm onNavigate={setActiveForm} />
        ) : (
          <PlansForm />
        )}
      </Grid>
    </Grid>
  )
}
