import { optionType, SubscribeContentForms } from 'types/types'
import { Modal } from 'components/Modal'
import { SubscribeContent } from './SubscribeContent'

type SubscribeModalProps = {
  countries: optionType[]
  activeForm: SubscribeContentForms
  setActiveForm: (form: SubscribeContentForms) => void
}
export const SubscribeModal = ({
  countries,
  activeForm,
  setActiveForm,
}: SubscribeModalProps) => (
  <Modal
    open
    closeButton
    onClose={() => setActiveForm('closed')}
    maxWidth='lg'
    scrollMode='body'
  >
    <SubscribeContent
      countries={countries}
      activeForm={activeForm}
      setActiveForm={setActiveForm}
    />
  </Modal>
)
