import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ButtonBase from '@mui/material/ButtonBase'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import AppBar from '@mui/material/AppBar'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Hidden from '@mui/material/Hidden'
import { Avatar } from 'components/Avatar'
import { useRouter } from 'next/router'
import { personFullName } from 'utils/personFullName'
import { LinkTo } from './LinkTo'
import { useAuth } from 'hooks/useAuth'
import { styled } from '@mui/material/styles'
import { ProducerKitsButton } from './ProducerKitsButton'
import { HamburgerMenu } from './HamburgerButton'
import { backgroundCSS } from './backgroundCSS'
import Head from 'next/head'
import { useCreditSaldo } from 'hooks/useCredits'
import { ProducerKitsLogo } from './ProducerKitsLogo'
import { useTranslation } from 'react-i18next'
import { useDoc } from 'hooks/useDoc'
import { usePlanCheck } from 'hooks/usePlanCheck'
import { ModalNoCredits } from './ModalNoCredits'
import { useWindowScroll } from 'hooks/useWindowScroll'
import { useFunctionRequest } from 'hooks/useFunctionRequest'
import { SubscribeModal } from 'containers/Subscribe/SubscribeModal'
import { headerMenu } from 'data/headerMenu'
import { navigationMenu } from 'data/navigationMenu'
import { TopSpacing } from './TopSpacing'
import { useModalRouter } from 'hooks/useModalRouter'
import { optionType, SubscribeContentForms } from 'types/types'

export const HEADER_HEIGHT_SCROLLED = 64
const HEADER_HEIGHT = 85
const MENU_SWITCH_WIDTH = 1050

const Header = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'headerScrolled' && prop !== 'menuOpen',
})<{ headerScrolled: boolean; menuOpen: boolean }>(
  ({ theme, headerScrolled, menuOpen }) => ({
    height: headerScrolled ? HEADER_HEIGHT_SCROLLED : HEADER_HEIGHT,
    top: '0px',
    padding: headerScrolled ? '0px' : '12px 0px',
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.appBar + 10, // +10 to overlay the mobile player
    transition: 'height 0.4s cubic-bezier(0, 0, 0, 1) 0s',
    overflow: 'hidden',
    boxShadow: 'unset',
    ...(headerScrolled || menuOpen
      ? {
          ...backgroundCSS,
        }
      : {
          background: 'unset',
        }),
    ...(menuOpen && {
      height: '100%',
    }),
    [theme.breakpoints.down(MENU_SWITCH_WIDTH)]: {
      padding: '0px',
    },
  }),
)
const GridLogo = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  width: 360,
  minWidth: 360,
  [theme.breakpoints.down('lg')]: {
    width: 160,
    minWidth: 160,
  },
  '> a': {
    color: '#fff',
  },
}))
const GridHeaderMenu = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(MENU_SWITCH_WIDTH)]: {
    display: 'none',
  },
}))
const GridHeaderAuthButtons = styled(Grid)(() => ({
  width: 256,
  minWidth: 256,
  justifyContent: 'right',
}))
const GridHeaderAccount = styled(Grid)(({ theme }) => ({
  width: 360,
  minWidth: 360,
  marginLeft: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: 160,
    minWidth: 160,
  },
  [theme.breakpoints.down(MENU_SWITCH_WIDTH)]: {
    display: 'none',
  },
}))
const GridHeaderSubscribe = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up(MENU_SWITCH_WIDTH)]: {
    display: 'none',
  },
  [theme.breakpoints.down(MENU_SWITCH_WIDTH)]: {
    width: 160,
    minWidth: 160,
    paddingRight: 10,
  },
}))
const HeaderLinkHover = styled(Grid)(() => ({
  width: 12,
  height: 3,
  borderRadius: 2,
  backgroundColor: 'rgba(255, 255, 255, 0)',
  margin: '2px calc(50% - 6px) 0px calc(50% - 6px)',
  transition: 'background 0.2s cubic-bezier(0, 0, 0, 1) 0s',
}))
const HeaderLink = styled(Grid)(({ theme }) => ({
  padding: '18px 16px 14px 16px',
  [theme.breakpoints.down('lg')]: {
    padding: '18px 12px 14px 12px',
  },
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  ':hover [data-comp="headerLinkHover"]': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}))
const MenuStyled = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    ...backgroundCSS,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
}))

/** Show on smartphones */
const GridMobile = styled(Grid)(({ theme }) => ({
  marginTop: 16,
  transition: 'visibility 0s, opacity 1s cubic-bezier(0, 0, 0, 1) 0s',
  a: {
    width: '100%',
  },
  [theme.breakpoints.up(MENU_SWITCH_WIDTH)]: {
    display: 'none',
  },
  '@media (max-height: 500px)': {
    marginTop: 8,
  },
}))
const ButtonBaseMobileItem = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  height: 36,
  marginBottom: 12,
  color: theme.palette.common.white,
  ...theme.typography.subtitle2,
  '@media (max-height: 500px)': {
    width: '50%',
    marginBottom: 8,
  },
}))
const ButtonBaseMobileMenu = styled(ButtonBase)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: 24,
  marginLeft: 'auto',
  [theme.breakpoints.up(MENU_SWITCH_WIDTH)]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: '-8px',
  },
}))
const GridAuthButtons = styled(Grid)(() => ({
  marginTop: 8,
}))

type MenuItemType = {
  label: string
  path: string
  showDesktop: boolean
  showDesktopHeaderMenu: boolean
  showMobileMenu: boolean
}
type ProducerKitsHeaderTypes = {
  scrolled?: boolean
  scrollFrom?: number
  disableTopSpacing?: boolean
  disableSubscribeButtons?: boolean
  overwriteMenu?: MenuItemType[]
  countries: optionType[]
}

export const dummyUserData = {
  firstNamePers: 'A',
  lastNamePers: 'B',
  id: 'ABC',
}

export const ProducerKitsHeader = ({
  scrolled,
  scrollFrom = 50,
  disableTopSpacing,
  overwriteMenu,
  disableSubscribeButtons,
  countries,
}: ProducerKitsHeaderTypes) => {
  const { t } = useTranslation('header')
  const { t: tcommon } = useTranslation('common')
  const { user, auth } = useAuth()
  const { query, route, push } = useRouter()
  const { data: person } = useDoc({ collection: 'persons', id: user?.uid })
  const makeRequest = useFunctionRequest()
  const { openModal } = useModalRouter()
  const [noCreditModalOpen, setNoCreditModalOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [activeSubscribeForm, setActiveSubscribeForm] =
    useState<SubscribeContentForms>('closed')

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen)

  const changeSubscribeForm = (form: SubscribeContentForms) => {
    setActiveSubscribeForm(form)
    openModal({ form })
  }

  useEffect(() => {
    if (
      activeSubscribeForm !== query?.subscribe &&
      typeof query?.subscribe === 'string' &&
      ['signup', 'signin', 'plans', 'passwordforget', 'closed'].includes(
        query?.subscribe,
      )
    ) {
      setActiveSubscribeForm(query.subscribe as SubscribeContentForms)
    }
    // activeSubscribeForm is not added because that will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (person?.status === 'inactive') {
      makeRequest({
        functionName: 'activeCampaignAction',
        body: { actionName: 'moveFromInactiveAccounts' },
      })
    }
    // Should only run when person changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person])

  const menu: MenuItemType[] = [
    ...(overwriteMenu ? overwriteMenu : headerMenu),
    ...navigationMenu(user?.uid),
  ]

  const handleLogout = () => {
    /** window.open is used here to refresh page after navigating */
    auth.signOut().then(() => window.open('/', '_self'))
  }
  const { y } = useWindowScroll()
  const headerScrolled = scrolled || y > scrollFrom
  const creditSaldo = useCreditSaldo()
  const { data, loading: loadingPlan } = usePlanCheck()
  const activePlan = !loadingPlan && data !== 'NO_PLAN'

  /** Account menu logic */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = (path: string) => {
    push(path)
    setAnchorEl(null)
  }

  return (
    <>
      <Head>
        {mobileMenuOpen && <style>{`body { overflow: hidden; }`}</style>}
      </Head>
      <Header headerScrolled={headerScrolled} menuOpen={mobileMenuOpen}>
        {activeSubscribeForm !== 'closed' && (
          <SubscribeModal
            countries={countries}
            activeForm={activeSubscribeForm}
            setActiveForm={form => {
              setActiveSubscribeForm(form)
              changeSubscribeForm(form)
            }}
          />
        )}
        {noCreditModalOpen && (
          <ModalNoCredits onClose={() => setNoCreditModalOpen(false)} />
        )}
        <Container maxWidth='xl'>
          <Grid
            container
            flexWrap='nowrap'
            sx={{
              height: headerScrolled ? HEADER_HEIGHT_SCROLLED : HEADER_HEIGHT,
              transition: 'height 0.4s cubic-bezier(0, 0, 0, 1) 0s',
            }}
            alignItems='center'
          >
            <GridLogo item marginLeft='-14px'>
              <LinkTo
                href={
                  user?.uid
                    ? 'https://members.producerkits.com'
                    : 'https://www.producerkits.com'
                }
              >
                <ProducerKitsLogo />
              </LinkTo>
            </GridLogo>
            <GridHeaderMenu item flexGrow={1} container justifyContent='center'>
              {menu.map(
                item =>
                  item.showDesktop && (
                    <LinkTo
                      key={item.label}
                      href={item.path}
                      variant='subtitle2'
                      color='inherit'
                    >
                      <HeaderLink item>
                        {item.label}
                        <HeaderLinkHover
                          {...(route.includes(item.path) && {
                            style: { backgroundColor: 'rgba(255,255,255,1)' },
                          })}
                          data-comp='headerLinkHover'
                        />
                      </HeaderLink>
                    </LinkTo>
                  ),
              )}
            </GridHeaderMenu>
            <GridHeaderAccount item>
              <Grid container justifyContent='flex-end'>
                {user?.uid ? (
                  <>
                    <Grid
                      container
                      spacing={2}
                      wrap='nowrap'
                      justifyContent={'flex-end'}
                    >
                      {!disableSubscribeButtons && (
                        <>
                          <Hidden lgDown>
                            <Grid item>
                              <ProducerKitsButton
                                size='small'
                                white
                                onClick={() => changeSubscribeForm('plans')}
                                style={{ width: '156px' }}
                              >
                                Upgrade For More!
                              </ProducerKitsButton>
                            </Grid>
                          </Hidden>
                          <Grid item>
                            <ProducerKitsButton
                              size='small'
                              variant='outlined'
                              white
                              onClick={() => changeSubscribeForm('plans')}
                              style={{
                                textDecoration:
                                  activePlan || creditSaldo === 0
                                    ? 'none'
                                    : 'line-through',
                              }}
                            >
                              {creditSaldo} Credits
                            </ProducerKitsButton>
                          </Grid>
                        </>
                      )}

                      <Grid item>
                        <ButtonBase
                          sx={{ borderRadius: '50%' }}
                          onClick={handleClick}
                        >
                          <Avatar personData={person || dummyUserData} />
                        </ButtonBase>
                        <MenuStyled
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          MenuListProps={{
                            sx: {
                              backgroundColor: 'rgba(0,0,0,0.0)',
                              minWidth: '220px',
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => navigate('/account?path=profile')}
                          >
                            <ListItemAvatar>
                              <Avatar personData={person} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                person?.lastNamePers
                                  ? personFullName(person)
                                  : user?.email || ''
                              }
                              secondary={
                                t<string>('desktop_edit_profile_text') as string
                              }
                              sx={{
                                '& .MuiListItemText-secondary': {
                                  color: '#fff',
                                },
                              }}
                            />
                          </MenuItem>
                          {menu.map(
                            item =>
                              item.showDesktopHeaderMenu && (
                                <MenuItem
                                  key={item.path}
                                  onClick={() => push(item.path)}
                                >
                                  {item.label}
                                </MenuItem>
                              ),
                          )}
                          <Divider />
                          <MenuItem onClick={handleLogout}>
                            {tcommon<string>('log_out') as string}
                          </MenuItem>
                        </MenuStyled>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <GridHeaderAuthButtons item container>
                    <Grid item>
                      <ProducerKitsButton
                        variant='outlined'
                        size='small'
                        onClick={() => changeSubscribeForm('signin')}
                      >
                        Log In
                      </ProducerKitsButton>
                    </Grid>
                    {!disableSubscribeButtons && (
                      <Grid item sx={{ marginLeft: 2 }}>
                        <ProducerKitsButton
                          size='small'
                          onClick={() => changeSubscribeForm('plans')}
                        >
                          Subscribe
                        </ProducerKitsButton>
                      </Grid>
                    )}
                  </GridHeaderAuthButtons>
                )}
              </Grid>
            </GridHeaderAccount>

            {!user?.uid && !disableSubscribeButtons && (
              <GridHeaderSubscribe item flexGrow={1}>
                <Grid container justifyContent='flex-end'>
                  <GridHeaderAuthButtons item container>
                    <Grid item sx={{ marginLeft: 2 }}>
                      <ProducerKitsButton
                        size='small'
                        onClick={() => changeSubscribeForm('signup')}
                      >
                        Subscribe
                      </ProducerKitsButton>
                    </Grid>
                  </GridHeaderAuthButtons>
                </Grid>
              </GridHeaderSubscribe>
            )}
            <ButtonBaseMobileMenu>
              <HamburgerMenu
                isToggled={mobileMenuOpen}
                onToggle={toggleMobileMenu}
              />
            </ButtonBaseMobileMenu>
          </Grid>
        </Container>

        {/** SMARTPHONE MENU */}
        <GridMobile
          container
          justifyContent='center'
          style={{
            visibility: mobileMenuOpen ? 'visible' : 'hidden',
            opacity: mobileMenuOpen ? 1 : 0,
          }}
        >
          {menu.map(
            item =>
              item.showMobileMenu && (
                <ButtonBaseMobileItem
                  key={item.path}
                  onClick={() => push(item.path)}
                >
                  {item.label}
                </ButtonBaseMobileItem>
              ),
          )}
          {user?.uid && (
            <>
              <Grid
                item
                xs={12}
                container
                justifyContent='center'
                paddingBottom='20px'
              >
                <ProducerKitsButton
                  size='small'
                  variant='outlined'
                  white
                  onClick={() => changeSubscribeForm('plans')}
                >
                  {creditSaldo} Credits
                </ProducerKitsButton>
              </Grid>
              <Grid item>
                <ProducerKitsButton
                  size='small'
                  white
                  onClick={() => changeSubscribeForm('plans')}
                  style={{ width: '156px' }}
                >
                  Upgrade For More!
                </ProducerKitsButton>
              </Grid>
            </>
          )}

          <GridAuthButtons container justifyContent='center' spacing={2}>
            {user?.uid ? (
              <Grid item>
                <ProducerKitsButton
                  variant='outlined'
                  size='small'
                  onClick={handleLogout}
                >
                  {tcommon<string>('log_out') as string}
                </ProducerKitsButton>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <ProducerKitsButton
                    variant='outlined'
                    size='small'
                    onClick={() => changeSubscribeForm('signin')}
                  >
                    {tcommon<string>('log_in') as string}
                  </ProducerKitsButton>
                </Grid>
                <Grid item sx={{ marginLeft: 2 }}>
                  <ProducerKitsButton
                    size='small'
                    white
                    onClick={() => changeSubscribeForm('signup')}
                  >
                    {tcommon<string>('sign_up') as string}
                  </ProducerKitsButton>
                </Grid>
              </>
            )}
          </GridAuthButtons>
        </GridMobile>
      </Header>
      {!disableTopSpacing && <TopSpacing />}
    </>
  )
}
