import { useEffect, useState } from 'react'
import { onSnapshot, doc } from 'firebase/firestore'
import { errorLogger } from 'utils/errorLogger'
import { useDocProps } from './useDoc'
import { firebaseObject } from 'containers/firebaseObject'

export const useDocListener = ({ collection, id }: useDocProps) => {
  const [docsData, setDocsData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    if (!id) {
      setLoading(false)
      return
    }
    onSnapshot(
      doc(firebaseObject.db, collection, id),
      docData => {
        setDocsData({ ...docData.data(), id: docData.id })
        setLoading(false)
      },
      error => {
        errorLogger(error)
        setLoading(false)
      },
    )
  }, [collection, id])
  return { data: docsData, loading }
}
