import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import MaterialIconButton from '@mui/material/IconButton'
import { IconButtonProps as MaterialIconButtonProps } from '@mui/material/IconButton/'

interface IconButtonProps
  extends Omit<MaterialIconButtonProps, 'label' | 'disabled'> {
  /**Visible label of the button*/
  label: string
  /**Boolean whether or not the button is active*/
  disabled?: boolean
  /**Data for inline styling*/
  style?: React.CSSProperties
  /**ID of the button */
  id?: string
}

export function IconButton({
  label,
  disabled,
  style,
  id,
  ...props
}: IconButtonProps) {
  return (
    <Tooltip
      key={disabled ? 1 : 2}
      title={label}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
    >
      <span id={id || label}>
        <MaterialIconButton style={style} disabled={disabled} {...props} />
      </span>
    </Tooltip>
  )
}
