export const ProducerKitsIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='1440'
    height='1440'
    viewBox='0 0 1440 1440'
    {...props}
  >
    <g transform='matrix(1.3333333,0,0,-1.3333333,0,1440)'>
      <g transform='translate(566.5314,579.2059)'>
        <path d='m 0,0 -47.673,47.673 23.84,23.837 c 13.148,13.145 34.533,13.141 47.673,0 6.344,-6.347 9.842,-14.812 9.842,-23.837 0,-9.024 -3.498,-17.489 -9.845,-23.836 z m 139.316,-272.613 c -10.291,-10.283 -27.036,-10.298 -37.327,0 L 0,-170.627 -101.989,-272.613 c -4.982,-4.985 -11.605,-7.728 -18.656,-7.728 h -0.008 c -7.047,0 -13.674,2.743 -18.66,7.728 -4.981,4.986 -7.728,11.613 -7.728,18.664 v 134.39 L -85.31,-181.286 0,-95.98 v 0 l 71.827,71.827 c 19.164,19.164 29.716,44.672 29.72,71.826 0,27.155 -10.556,52.663 -29.72,71.827 -39.599,39.599 -104.044,39.607 -143.65,0 L -143.646,47.673 -47.988,-47.99 -85.31,-85.314 -199.633,29.006 c -10.291,10.295 -10.291,27.04 0,37.327 l 90.486,90.487 c 60.189,60.185 158.116,60.185 218.297,0.004 60.185,-60.185 60.185,-158.116 0,-218.297 L 37.327,-133.3 139.313,-235.289 c 10.291,-10.287 10.291,-27.033 0.003,-37.324 m -6.016,139.313 23.84,23.837 c 86.643,86.646 86.643,227.627 0,314.277 -86.65,86.643 -227.627,86.639 -314.277,-0.004 l -90.486,-90.487 c -36.75,-36.749 -36.75,-96.547 0,-133.303 l 32.717,-32.714 v -202.255 c 0,-25.177 9.805,-48.848 27.607,-66.65 17.802,-17.806 41.473,-27.611 66.643,-27.607 h 0.022 c 25.169,0 48.833,9.805 66.635,27.603 L 0,-266.6 53.999,-320.603 c 36.749,-36.741 96.547,-36.752 133.304,0.004 36.749,36.753 36.749,96.551 0,133.3 z' />
      </g>
    </g>
  </svg>
)
