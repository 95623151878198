import styled from '@mui/material/styles/styled'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import MaterialSwitch from '@mui/material/Switch'
import { Theme } from '@mui/material/styles/createTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'

const SWITCH_WIDTH = 90
const SWITCH_HEIGHT = 36
const SwitchStyled = styled(MaterialSwitch)(({ theme }) => ({
  padding: 8,
  width: SWITCH_WIDTH,
  height: SWITCH_HEIGHT + 16,
  '& .MuiSwitch-track': {
    width: SWITCH_WIDTH,
    height: SWITCH_HEIGHT,
    borderRadius: SWITCH_HEIGHT / 2,
    backgroundColor: 'rgba(0,0,0,0)',
    border: '2px solid #fff',
    opacity: 1,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: SWITCH_HEIGHT - 6,
      height: SWITCH_HEIGHT - 6,
    },
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(38px)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(0,0,0,0)',
        border: '2px solid #fff',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: SWITCH_HEIGHT - 12,
    height: SWITCH_HEIGHT - 12,
    margin: 5,
    color: theme.palette.primary.main,
  },
}))

export const SwitchToggle = ({
  value = false,
  onChange,
  labelLeft,
  labelRight,
}: any) => {
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <FormGroup>
      <Typography component='div' variant='h5'>
        <Grid
          component='label'
          container
          alignItems='center'
          spacing={1}
          position='relative'
        >
          <Grid item minWidth={'100px'}>
            <Typography
              variant='subtitle2'
              fontWeight={!value ? 'bold' : 'regular'}
            >
              {labelLeft}
            </Typography>
          </Grid>
          <Grid item>
            <SwitchStyled
              checked={value}
              onChange={e => onChange(e.target.value, e)}
              name='checkedC'
            />
          </Grid>
          <Grid item minWidth={'80px'}>
            <Typography
              variant='subtitle2'
              fontWeight={value ? 'bold' : 'regular'}
            >
              {labelRight}
            </Typography>
          </Grid>
          {!downSM && (
            <Grid
              item
              sx={{
                position: 'absolute',
                left: downMD ? '95%' : '85%',
                top: downMD ? '-30px' : '-50px',
                width: '100px',
              }}
            >
              <Typography variant='caption'>2 months free</Typography>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='27.228'
                height='19.731'
                viewBox='0 0 27.228 19.731'
                style={{ marginLeft: '24px' }}
              >
                <g transform='matrix(0.966, -0.259, 0.259, 0.966, -0.554, 7.858)'>
                  <path
                    d='M4.616,23.32C1.69,23.158-2.844,18.267,2.422,0'
                    transform='translate(3.797 11.226) rotate(-106.982)'
                    fill='none'
                    stroke='#fff'
                    strokeLinecap='round'
                    strokeWidth='2'
                  />
                  <path
                    d='M1.983,2.41,3.424,0,0,2.858,4,4.041Z'
                    transform='matrix(0.999, -0.052, 0.052, 0.999, 0, 7.008)'
                    fill='none'
                    stroke='#fff'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                  />
                </g>
              </svg>
            </Grid>
          )}
        </Grid>
      </Typography>
    </FormGroup>
  )
}
