import { getDataOnly } from 'utils/getDataOnly'
import { useDoc } from './useDoc'

export const usePlans = () => {
  const { data: plansDataRaw } = useDoc({
    collection: 'plans',
    id: 'plansData',
  })
  const { data: providerPlansRaw } = useDoc({
    collection: 'plans',
    id: 'providerPlans',
  })

  const plansData = getDataOnly(plansDataRaw)
  const providerPlans = getDataOnly(providerPlansRaw)

  const getPlanByParams = ({ product, period, currency }) => {
    if (!plansData || !providerPlans || !product || !period) return

    const planData = plansData[product]
    const providerPlan: any = Object.values(providerPlans).find(
      (plan: any) =>
        plan.period === period &&
        plan.product === product &&
        plan.currency === currency &&
        plan.archived === false,
    )

    if (!planData) return

    const periodPricing = planData.periodPrices?.[period]
    const periodPrice = periodPricing?.[currency]
    const periodPriceWithFee = periodPricing?.[`${currency}WithFee`]

    return {
      currency: providerPlan?.currency,
      providerPlanID: providerPlan?.providerPlanID,
      product: planData?.product,
      credits: planData?.credits,
      period: period,
      periodPrices: planData?.periodPrices,
      perks: planData.perks,
      periodPrice,
      periodPriceWithFee,
    }
  }

  const getPlanByID = providerPlanID => {
    if (!providerPlanID || !plansData || !providerPlans) return

    const providerPlan = providerPlans[providerPlanID]
    const planData = plansData[providerPlan?.product]

    const periodPricing = planData?.periodPrices?.[providerPlan?.period]
    const periodPrice = periodPricing?.[providerPlan?.currency]
    const periodPriceWithFee =
      periodPricing?.[`${providerPlan?.currency}WithFee`]

    return {
      providerPlanID,
      currency: providerPlan?.currency,
      product: providerPlan?.product,
      credits: planData?.credits,
      period: providerPlan?.period,
      periodPrices: planData?.periodPrices,
      perks: planData?.perks,
      periodPrice,
      periodPriceWithFee,
    }
  }

  return { getPlanByParams, getPlanByID }
}
