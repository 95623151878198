import { Country_SS } from '@fivano/models'
import { firebaseObject } from 'containers/firebaseObject'
import { getDocs, query, collection } from 'firebase/firestore'
import { optionType } from 'types/types'
import { flattedFirestore } from '../utils/flattedFirestore'

const getCountries = async () => {
  /** Get array of countries directly from DB */
  const countries = (await getDocs(
    query(collection(firebaseObject.db, 'countries')),
  ).then(response =>
    response.docs.map(i => flattedFirestore(i.data())),
  )) as Country_SS[]

  return countries
}

const getSortedCountries = async () => {
  /** Convert countries array to correctly sorted country options */
  const countries = (await getDocs(
    query(collection(firebaseObject.db, 'countries')),
  ).then(response =>
    response.docs.map(i => flattedFirestore(i.data())),
  )) as Country_SS[]
  const countryOptions = countries
    .map(country => ({
      value: country.code,
      label: country.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)) as optionType[]
  const featuresCountries = ['AU', 'GB', 'US'] // most right value is at top
  featuresCountries.forEach(item => {
    const index = countryOptions.findIndex(country => country.value === item)
    if (index !== -1) {
      countryOptions.unshift(countryOptions.splice(index, 1)[0])
    }
  })
  return countryOptions
}

export { getCountries, getSortedCountries }
