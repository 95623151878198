import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Radio from '@mui/material/Radio'
import { Theme } from '@mui/material/styles/createTheme'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { PeriodTypes } from 'containers/Subscribe/PlansForm'
import { ProducerKitsButton } from './ProducerKitsButton'

const FormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'outlined' && prop !== 'isChecked',
})<any>(({ theme, outlined }) => ({
  ...(outlined && {
    border: `2px solid ${theme.palette.common.white}`,
  }),
  padding: '10px',
  marginBottom: '16px',
  borderRadius: '10px',
  width: '100%',
  boxSizing: 'border-box',
  '& .MuiTypography-root': {
    fontSize: 20,
  },
  '&:hover': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}))

const TypographyStyled = styled(Typography, {
  shouldForwardProp: prop => prop !== 'hasError',
})<any>(({ hasError, theme }) => ({
  fontSize: '0.75rem',
  width: '100%',
  ...(hasError && {
    color: theme.palette.error.main,
  }),
}))
type ValueType = { label: string; value: string }

type PlanRadioGroupProps = {
  /** planSelected value */
  planSelected: ValueType
  /** planSelected value */
  periodSelected: PeriodTypes
  /** onChange */
  onChange: (value: ValueType) => void
  /**Visible label */
  label?: string
  /**Name of the PlanRadioGroup*/
  options: ValueType[]
  /**Boolean whether the direction of the PlanRadioGroup is row or not*/
  row?: boolean
  /**Shown text when an error occurs*/
  helperText?: string
  activePlan?: any
}
export const PlanRadioGroup = ({
  label,
  options,
  row,
  helperText,
  planSelected,
  periodSelected,
  onChange,
  activePlan,
}: PlanRadioGroupProps) => {
  const radioOptions = options?.length ? options : []
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  return (
    <FormControl sx={{ width: '100%', left: '11px' }}>
      <FormGroup row={row}>
        {label && <FormLabel component='legend'>{label}</FormLabel>}
        {radioOptions.map(option => {
          const isCurrent =
            activePlan?.product === option.value &&
            activePlan?.period === periodSelected
          const isChecked = !isCurrent && planSelected.value === option.value

          return (
            <Grid container key={option.label} position='relative'>
              <FormControlLabelStyled
                outlined
                key={option.label}
                label={option.label}
                value={option}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: downLG ? (downSM ? 16 : 18) : 20,
                  },
                }}
                control={
                  <Radio
                    color='primary'
                    onChange={() => onChange(option)}
                    checked={isChecked}
                    disabled={isCurrent}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
              />
              {!activePlan?.product && option.value === 'premium' && (
                <Hidden smDown>
                  <ProducerKitsButton
                    size='small'
                    onClick={() => {}}
                    sx={{
                      position: 'absolute',
                      right: '6%',
                      top: '17%',
                      width: downLG ? '75px' : '130px',
                      borderRadius: '5px',
                      fontSize: { downLG: 12 },
                    }}
                  >
                    {downLG ? 'POPULAR' : 'MOST POPULAR'}
                  </ProducerKitsButton>
                </Hidden>
              )}
              {isCurrent && (
                <ProducerKitsButton
                  size='small'
                  onClick={() => {}}
                  sx={{
                    position: 'absolute',
                    right: '6%',
                    top: '17%',
                    width: downLG ? '75px' : '130px',
                    borderRadius: '5px',
                    fontSize: { downLG: 12 },
                  }}
                >
                  {downLG ? 'CURRENT' : 'CURRENT PLAN'}
                </ProducerKitsButton>
              )}
            </Grid>
          )
        })}
      </FormGroup>
      <TypographyStyled variant='caption'>{helperText}</TypographyStyled>
    </FormControl>
  )
}
