import { isBefore } from 'date-fns'
import {
  getDoc,
  doc,
  getDocs,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore'
import { convertToDate } from 'utils/convertToDate'

export const validateCoupon = async (value, db, uid) => {
  let message = 'Coupon error'
  let valid = false

  if (value.length > 0) {
    await getDoc(doc(db, 'couponCodes', value)).then(response => {
      if (!response.exists()) {
        message = 'Coupon code invalid'
        return
      }
      const couponCode = response.data()
      if (couponCode.code === value) {
        message = `Coupon valid! You get ${couponCode.freeCreditAmount} credits for free once after completing your order.`
        valid = true
      }
      if (
        couponCode.startDate &&
        isBefore(new Date(), convertToDate(couponCode.startDate))
      ) {
        message = 'Coupon code not started'
        valid = false
      }
      if (
        couponCode.endDate &&
        isBefore(convertToDate(couponCode.endDate), new Date())
      ) {
        message = 'Coupon code expired, not usable anymore'
        valid = false
      }
    })
    if (uid) {
      await getDocs(
        query(
          collection(db, 'creditTransactions'),
          where('uid', '==', uid),
          /** Ensures only docs with couponCodeID return */
          where('couponCodeID', '!=', null),
          orderBy('couponCodeID'),
        ),
      ).then(response => {
        if (!response.empty) {
          message = 'You already used a coupon code'
          valid = false
        }
      })
    }
  } else {
    valid = true
  }
  return { message, valid }
}
