import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { ProducerKitsButton } from './ProducerKitsButton'
import { useTranslation } from 'react-i18next'

type ModalNoCreditsProps = {
  onClose: () => void
}

export function ModalNoCredits({ onClose }: ModalNoCreditsProps) {
  const { t: tcommon } = useTranslation('common')
  return (
    <Dialog open onClose={onClose} maxWidth='xs'>
      <DialogTitle>Inactive Plan</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          You credits are frozen because you currently do not have an active
          paid plan. Please upgrade to a paid plan to use your credits.
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0px 24px 24px 24px' }}>
        <ProducerKitsButton onClick={onClose} size='small'>
          {tcommon<string>('close') as string}
        </ProducerKitsButton>
      </DialogActions>
    </Dialog>
  )
}
