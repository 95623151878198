import { config } from 'configs/config'
import { firebaseObject } from 'containers/firebaseObject'
import { useAuth } from './useAuth'

type requestParams = {
  functionName: string
  body?: any
  type?: 'public' | 'admin' | 'testUtils'
  showResponse?: boolean
  idToken?: string
}

const functionRequest = async ({
  functionName,
  body,
  type,
  idToken,
}: requestParams) => {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  if (idToken) headers['id-token'] = idToken
  return fetch(`${config.backendURL}${type || 'functions'}/${functionName}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 404) {
        throw response.statusText
      } else {
        return response.json().then(error => {
          throw error
        })
      }
    }
  })
}

export const useFunctionRequest = () => {
  const { user } = useAuth()

  const makeRequest = async ({
    functionName,
    body,
    type,
    idTokenOverwrite,
  }: requestParams & { idTokenOverwrite?: string }) => {
    const idToken = idTokenOverwrite || (await user?.getIdToken())
    return await functionRequest({
      functionName,
      body,
      type,
      idToken,
    })
  }
  return makeRequest
}

export const backendRequest = async ({
  functionName,
  body,
  type,
}: requestParams) => {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  headers['id-token'] = await firebaseObject.auth.currentUser?.getIdToken()
  return fetch(`${config.backendURL}${type || 'functions'}/${functionName}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 404) {
        throw response.statusText
      } else {
        return response.json().then(error => {
          throw error
        })
      }
    }
  })
}
