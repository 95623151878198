import React from 'react'
import MaterialAvatar from '@mui/material/Avatar'
import colors from '@mui/material/colors/common'
import styled from '@mui/material/styles/styled'

const MaterialAvatarStyled = styled(MaterialAvatar, {
  shouldForwardProp: prop =>
    prop !== 'color' &&
    prop !== 'centerAvatar' &&
    prop !== 'button' &&
    prop !== 'size',
})<any>(({ theme, color, centerAvatar, button, size }) => ({
  ...(centerAvatar && {
    margin: '0 auto',
  }),
  ...(color && {
    color: theme.palette.getContrastText(color),
    backgroundColor: color,
  }),
  ...(button && {
    cursor: 'pointer',
    '& :hover': {
      border: `2px solid ${theme.palette.primary}`,
    },
  }),
  ...(size === 'small' && { width: 30, height: 30, fontSize: 16 }),
  ...(size === 'medium' && { width: 64, height: 64, fontSize: 28 }),
  ...(size === 'large' && { width: 128, height: 128, fontSize: 48 }),
}))
const typeColors: any = colors
const colorOptions = Object.keys(typeColors)

const stringToColor = (string: any) => {
  let hash = 0
  for (const i in string) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  const float = parseFloat(`0.${Math.abs(hash)}`)
  const index = Math.ceil(float * (colorOptions.length - 1))
  return typeColors[colorOptions[index]][100] || '#303030'
}
type AvatarPerson = {
  id?: string
  uid?: string
  firstNamePers: string
  middleNamePers?: string
  lastNamePers: string
  avatarImagePers?: boolean
  updatedAtPers?: Date | null
}

type AvatarProps = {
  /** personData object from Person or Profile to destructure Avatar letters from and set Avatar Image URL*/
  personData: AvatarPerson
  size?: 'small' | 'medium' | 'large'
  onClick?: () => void
  centerAvatar?: boolean
}

export const Avatar = ({
  size,
  personData,
  onClick,
  centerAvatar = false,
}: AvatarProps) => {
  return (
    <MaterialAvatarStyled
      onClick={onClick}
      size={size}
      color={stringToColor(
        `${personData?.id || ''}${personData?.uid || ''}${
          personData?.firstNamePers || ''
        }${personData?.lastNamePers || ''}`,
      )}
      centerAvatar={centerAvatar}
      button={onClick}
    >
      {personData?.firstNamePers?.charAt(0)}
      {personData?.lastNamePers?.charAt(0)}
    </MaterialAvatarStyled>
  )
}
