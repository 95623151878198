import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from './IconButton'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { DialogProps } from '@mui/material/Dialog/Dialog'
import styled from '@mui/material/styles/styled'

const DialogHeader = styled(Grid)(() => ({
  height: '64px',
  padding: '8px 8px 8px 24px',
}))
const DialogStyled = styled(Dialog, {
  shouldForwardProp: prop =>
    prop !== 'customMaxWidth' && prop !== 'customheight',
})<any>(({ theme, customMaxWidth, customHeight }) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
      maxHeight: '100%',
    },
  },
  '& .MuiDialog-paper': {
    backgroundImage: 'unset',
    backgroundColor: '#161A1A',
    [theme.breakpoints.down('sm')]: {
      margin: '16px',
      maxWidth: 'calc(100% - 32px) !important',
      width: 'calc(100% - 32px)',
    },
  },
  '& .MuiDialog-paperScrollPaper': {
    height: customHeight || 'auto',
    maxWidth: customMaxWidth,
    borderRadius: '10px',
    overflow: 'hidden',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0,0,0,0.70)',
  },
}))

type ModalProps = {
  /** Title of modal, shows at the top */
  title?: string
  /**Boolean whether the modal is open or not*/
  open: boolean
  /**Children elements of the modal */
  children: any
  /**Function that gets triggered when the modal is closed */
  onClose?: () => void
  /**Boolean if there is a closing button on the modal */
  closeButton?: boolean
  /**Boolean whether the modal is fullWidth relative to its parent */
  fullWidth?: boolean
  /**Custom height for the modal */
  customHeight?: string
  /**String for the css layout*/
  dialogClasses?: any
  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: DialogProps['maxWidth']
  /**Custom width for the modal */
  customMaxWidth?: number
  /**Boolean whether pressing the escape key closes the modal */
  disableEscapeKeyDown?: boolean
  /** Close label */
  closeLabel?: string
  scrollMode?: 'paper' | 'body'
}
export function Modal({
  title,
  open,
  children,
  onClose,
  closeButton,
  fullWidth = true,
  maxWidth = 'md',
  customMaxWidth,
  customHeight,
  dialogClasses,
  disableEscapeKeyDown = false,
  closeLabel = 'Sluiten',
  scrollMode = 'paper',
  ...props
}: ModalProps) {
  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      classes={dialogClasses}
      scroll={scrollMode}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      customMaxWidth={customMaxWidth}
      customheight={customHeight}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...props}
    >
      {title && (
        <DialogHeader
          container
          alignItems='center'
          justifyContent='space-between'
          wrap='nowrap'
        >
          <Grid item>
            <Typography variant='h6'>{title}</Typography>
          </Grid>
          {closeButton && (
            <IconButton label={closeLabel} onClick={onClose} size='large'>
              <CloseIcon />
            </IconButton>
          )}
        </DialogHeader>
      )}
      {!title && closeButton && (
        <Grid container justifyContent='right' height='0px'>
          <Grid zIndex='1'>
            <IconButton label={closeLabel} onClick={onClose} size='large'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {children}
    </DialogStyled>
  )
}
