import React from 'react'
import { useFunctionRequest } from 'hooks/useFunctionRequest'
import { errorLogger } from 'utils/errorLogger'
import { useSnackbar } from 'components/useSnackbar'

export const useResetPassword = () => {
  const [emailSend, setEmailSend] = React.useState(false)
  const { openSnack, Snackbar } = useSnackbar()
  const makeRequest = useFunctionRequest()

  const resetPassword = (email: string) => {
    makeRequest({
      functionName: 'passwordResetEmail',
      body: { email: email },
      type: 'public',
      showResponse: false,
    })
      .then(() => {
        setEmailSend(true)
        openSnack({ message: 'Password reset mail send', severity: 'success' })
      })
      .catch(error => {
        openSnack({ message: error.message, severity: 'error' })
        errorLogger(error)
      })
  }
  return { resetPassword, emailSend, Snackbar }
}
