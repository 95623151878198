import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { AlertProps } from '@mui/material/Alert'
import { AlertColor } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

type SnackbarSingleProps = {
  open: boolean
  message: string
  onClose: (e: any) => void
  authHideDuration?: number
  severity?: AlertColor
}

export const SnackbarSingle = ({
  severity = 'success',
  open,
  authHideDuration = 5000,
  onClose,
  message,
}: SnackbarSingleProps) => (
  <Snackbar
    open={open}
    autoHideDuration={authHideDuration}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert onClose={onClose} severity={severity}>
      {message}
    </Alert>
  </Snackbar>
)
