import Link from '@mui/material/Link'
import styled from '@mui/material/styles/styled'

const LinkStyled = styled(Link)(() => ({
  cursor: 'pointer',
}))

export const LinkClick = props => {
  return <LinkStyled component='span' {...props} />
}
