import { useRouter } from 'next/router'

type OpenModalTypes = {
  form?: 'signup' | 'signin' | 'plans' | 'passwordforget' | 'closed'
  product?: 'basic' | 'premium' | 'pro'
  period?: 'month' | 'year'
}

export const useModalRouter = () => {
  const { query, replace } = useRouter()
  const openModal = ({ form, product, period }: OpenModalTypes) => {
    const newQuery = {}
    if (form) newQuery['subscribe'] = form
    if (product) newQuery['product'] = product
    if (period) newQuery['period'] = period
    return replace(
      {
        query: { ...query, ...newQuery },
      },
      undefined,
      { scroll: false },
    )
  }
  return { openModal }
}
