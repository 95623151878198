import React, { useState } from 'react'
import { AlertColor } from '@mui/material/Alert'
import { SnackbarSingle } from './SnackbarSingle'

type openSnackTypes = {
  message: string
  authHideDuration?: number
  severity?: AlertColor
}

export const useSnackbar = () => {
  const [open, setOpen] = useState(false)
  const [snackbarProps, setSnackbarProps] = useState<openSnackTypes>({
    message: 'Loading',
  })
  return {
    openSnack: (data: openSnackTypes) => {
      setSnackbarProps(data)
      setOpen(true)
    },
    Snackbar: () => (
      <SnackbarSingle
        {...snackbarProps}
        open={open}
        onClose={() => setOpen(false)}
      />
    ),
  }
}
