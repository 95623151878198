import { isValid, parseISO } from 'date-fns'

export const convertToDate = date => {
  if (date?.seconds) return new Date(date.seconds * 1000)
  if (isValid(new Date(date))) {
    return new Date(date)
  } else if (isValid(date)) {
    if (typeof date !== 'string') {
      return date
    } else {
      return parseISO(date)
    }
  } else {
    return null
  }
}
