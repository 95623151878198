import { config } from 'configs/config'
import { firebaseObject } from 'containers/firebaseObject'
import { collection, addDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/nextjs'
import { errorCodeCheck } from './errorCodeCheck'

export const errorLogger = (error: any, type?: string) => {
  const errorObject: any = {
    error: error ? JSON.stringify(error) : 'NO ERROR FOUND FIVANO',
    errorDate: new Date(),
    location: null,
    type: type || 'unknown',
  }
  let toSentry = errorCodeCheck(error.code).toSentry

  if (config.environment === 'production' && toSentry)
    Sentry?.captureException(error)
  if (window?.location) {
    const locationJSON = JSON.stringify(window.location)
    if (locationJSON) errorObject.location = locationJSON
  }
  if (config.environment === 'development') console.error(error, errorObject)
  addDoc(collection(firebaseObject.db, 'errorLogger'), errorObject)
}
