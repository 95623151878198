import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import Link from '@mui/material/Link'
import { useForm } from 'react-hook-form'
import { TextField } from 'components/TextField'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { useResetPassword } from 'hooks/useResetPassword'
import { Trans, useTranslation } from 'react-i18next'
import { SubscriptionContainer } from './SignUpForm'
import { ProducerKitsLogo } from 'components/ProducerKitsLogo'
import { LinkClick } from 'components/LinkClick'
import { SubscribeContentForms } from 'types/types'

type PasswordForgetFormProps = {
  onNavigate: (form: SubscribeContentForms) => void
}

export const PasswordForgetForm = ({ onNavigate }: PasswordForgetFormProps) => {
  const { t: tcommon } = useTranslation('common')
  const { t: taccount } = useTranslation('account')
  const [emailSentTo, setEmailSentTo] = useState('')
  const formObject = useForm()
  const { resetPassword, emailSend, Snackbar } = useResetPassword()

  const submit = data => {
    setEmailSentTo(data.email)
    resetPassword(data.email)
  }

  const onSubmit = formObject.handleSubmit(submit)

  return (
    <SubscriptionContainer onSubmit={onSubmit}>
      <Snackbar />
      <Grid container spacing={3} justifyContent='center'>
        <Hidden smDown>
          <Grid item paddingRight='5%'>
            <ProducerKitsLogo size='lg' />
          </Grid>
        </Hidden>
        {emailSend && emailSentTo ? (
          <Grid item xs={12}>
            <Typography>
              {taccount<string>('reset_password_email_send_message', {
                email: emailSentTo,
              })}
            </Typography>
            <Typography variant='caption'>
              <Trans
                i18nKey='reset_password_email_send_message_caption'
                t={taccount}
                components={[
                  <Link
                    component='button'
                    variant='caption'
                    onClick={() => setEmailSentTo('')}
                  />,
                ]}
              />
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography gutterBottom variant='h6'>
                Reset Password
              </Typography>
              <Typography gutterBottom>
                Reset your password by entering your email address below and
                pressing the 'Reset Password' button.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={tcommon<string>('email')}
                name='email'
                size='medium'
                formObject={formObject}
                rules={{
                  required: {
                    value: true,
                    message: tcommon<string>('required_field'),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                    message: tcommon<string>('rules_pattern_message'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ProducerKitsButton
                fullWidth
                white
                size='large'
                onClick={onSubmit}
                type='submit'
              >
                Reset Password
              </ProducerKitsButton>
            </Grid>
            <Grid item xs={12}>
              <Typography align='center'>
                Back to log in?{' '}
                <LinkClick color='#ffffff' onClick={() => onNavigate('signin')}>
                  Log in here
                </LinkClick>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </SubscriptionContainer>
  )
}
